import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import AuditItemForm from "../forms/Forms/AuditItemForm/AuditItemForm";
import {
  useCreateAuditItemMutation,
  useUpdateAuditItemMutation,
} from "../../features/audit/auditApiSlice";

function AuditItemDialog({ dialog, handleClose }) {
  const { data } = dialog;
  const { auditItem, auditId } = data;
  const { handleSubmit, control, unregister } = useForm({
    defaultValues: {
      ...auditItem,
    },
  });

  const [createAuditItem, { isError }] = useCreateAuditItemMutation();
  const [updateAuditItem, { isError: isErrorUpdate }] =
    useUpdateAuditItemMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    // Check edit or create
    let createResult = null;
    try {
      if (dialog.type === "CREATE") {
        createResult = await createAuditItem({
          auditId,
          auditItemData: data,
        }).unwrap();
      }
      if (dialog.type === "EDIT") {
        createResult = await updateAuditItem({
          _id: auditItem?._id,
          updateFields: data,
        }).unwrap();
      }

      console.log("reeeeeeeeeseeeeeet");
      unregister("responsibleUser");
      if (createResult?.type === "success") {
        // console.log("result :>> ", result);
        dispatch(
          setDialog({
            title: `Auditpunkt ${
              dialog.type === "CREATE" ? "erstellt" : "geändert"
            }!`,
            type: "SUCCESS",
            data: {
              successText: `Auditpunkt wurde erfolgreich ${
                dialog.type === "CREATE" ? "erstellt" : "geändert"
              }`,
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <Dialog open maxWidth="xl" fullWidth>
        <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
        <form>
          <DialogContent>
            <AuditItemForm
              auditItem={dialog?.data?.auditItem ?? null}
              control={control}
              type={dialog.type}
            />
          </DialogContent>
          {(isError || isErrorUpdate) && (
            <div style={{ marginTop: 15 }}>
              <ErrorAlert
                errorMessage={`Fehler beim ${
                  dialog.type === "CREATE" ? "erstellen" : "ändern"
                } des Auditpunkts`}
              />
            </div>
          )}
          <DialogActions>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              autoFocus
              onClick={handleSubmit(onSubmit)}
            >
              {`${dialog.type === "CREATE" ? "erstellen" : "speichern"}`}
            </Button>
            <Button onClick={handleClose} color="primary">
              Beenden
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

AuditItemDialog.propTypes = {
  dialog: PropTypes.object,
  handleClose: PropTypes.func,
};

export default AuditItemDialog;
