import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

function PreviewDocument() {
  let { fileId } = useParams();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (fileId) {
      const openDialog = () => {
        dispatch(
          setDialog({
            title: "Datei anzeigen",
            type: "CREATE",
            content: "file-show",
            redirect: "/",
            data: {
              fileData: { _id: fileId, fileName: "TEST.docx" },
              action: "CONFIRMATION",
            },
          })
        );
      };
      openDialog();
    }
  }, [fileId, dispatch]); // Only depends on fileId and dispatch

  return <div>PreviewDocument with id: {fileId}</div>;
}

export default PreviewDocument;
