import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText } from "@mui/material";
import Iconify from "../../../../components/iconify";
import {
  checkAllowEditingDocument,
  handleShowReleaseButton,
  hasUserConfirmedReading,
} from "../../../../utils/fileUtils";

function FileDataTableItemMenu({
  fileData,
  handleEditFileData,
  userId,
  isAdmin,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleButtonClick = (event, fileData, type) => {
    handleEditFileData(event, fileData, type);
    handleClose(event);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="primary"
        sx={{ ml: 1 }}
        onClick={handleClick}
      >
        BEARBEITEN
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {hasUserConfirmedReading(fileData, userId) && (
          <MenuItem
            onClick={(event) =>
              handleButtonClick(event, fileData, "CONFIRMATION")
            }
          >
            LESEBESTÄTIGUNG
          </MenuItem>
        )}
        <MenuItem
          onClick={(event) => handleButtonClick(event, fileData, "SHOW")}
        >
          ANZEIGEN
        </MenuItem>
        {fileData?.status === "released" &&
          fileData?.type === "FB" &&
          fileData?.proofFolder && (
            <MenuItem
              onClick={(event) =>
                handleButtonClick(event, fileData, "NACHWEIS")
              }
            >
              NACHWEIS ERZEUGEN
            </MenuItem>
          )}
        {checkAllowEditingDocument(fileData, isAdmin, userId) && (
          <MenuItem
            onClick={(event) => handleButtonClick(event, fileData, "EDIT")}
          >
            BEARBEITEN
          </MenuItem>
        )}
        {handleShowReleaseButton(isAdmin, fileData, userId) && (
          <MenuItem
            onClick={(event) => handleButtonClick(event, fileData, "RELEASE")}
          >
            FREIGEBEN
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem
            onClick={(event) => handleButtonClick(event, fileData, "ASSIGN")}
          >
            BENUTZER ZUWEISEN
          </MenuItem>
        )}
        <MenuItem
          onClick={(event) => handleButtonClick(event, fileData, "DOWNLOAD")}
        >
          DOWNLOAD
        </MenuItem>
        {isAdmin && (
          <MenuItem
            onClick={(event) => handleButtonClick(event, fileData, "DELETE")}
          >
            <ListItemIcon>
              <Iconify
                icon="material-symbols:delete-outline"
                style={{
                  color: "red",
                }}
              />
            </ListItemIcon>
            <ListItemText>{"LÖSCHEN"}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

FileDataTableItemMenu.propTypes = {
  handleEditFileData: PropTypes.func,
  fileData: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default FileDataTableItemMenu;
