import React from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, TextField as MuiTextField } from "@mui/material";
import { useController } from "react-hook-form";
import Iconify from "../iconify";

function CounterField({ control, name, label, min = 1, max = 999, fullWidth }) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: true,
      validate: (value) =>
        (value >= min && value <= max) ||
        `Value must be between ${min} and ${max}`,
    },
  });

  const handleIncrement = () => {
    if (field.value < max) {
      field.onChange(field.value + 1);
    }
  };

  const handleDecrement = () => {
    if (field.value > min) {
      field.onChange(field.value - 1);
    }
  };

  const handleChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= min && inputValue <= max) {
      field.onChange(inputValue);
    }
  };

  return (
    <MuiTextField
      label={label}
      value={field.value ?? min} // Default to min value if undefined
      onChange={handleChange}
      type="number"
      name={field.name}
      inputRef={field.ref}
      error={!!error}
      helperText={error?.message}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <IconButton size="small" onClick={handleIncrement}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton size="small" onClick={handleDecrement}>
              <Iconify icon="eva:minus-fill" />
            </IconButton>
          </div>
        ),
      }}
    />
  );
}

CounterField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  fullWidth: PropTypes.bool,
};

CounterField.defaultProps = {
  label: "",
  min: 1,
  max: 999,
  fullWidth: true,
};

export default CounterField;
