import { Helmet } from "react-helmet-async";
// @mui
import { Button, Container, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
import {
  useGetTenantInformationQuery,
  useGetTenantsQuery,
} from "../features/tenants/tenantsApiSlice";
import { getCurrentUserCount } from "../utils/tenantUtils";
import Loading from "../components/spinners/Loading";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import TenantOverview from "../sections/@dashboard/admin/TenantOverview";
import AdminMainMenu from "../sections/@dashboard/admin/AdminMainMenu";
import SupportOverview from "../sections/@dashboard/admin/SupportOverview";

// ----------------------------------------------------------------------

export default function AdminPage() {
  let location = useLocation();
  const { data: tenants, isLoading } = useGetTenantsQuery();
  const { data: tenant, isLoading: isLoadingTenant } =
    useGetTenantInformationQuery();

  const userCount = tenant && getCurrentUserCount(tenant);

  if (isLoading || isLoadingTenant) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title> Digit QM | Admin Zentrale </title>
      </Helmet>

      <Container maxWidth="xl">
        {location?.pathname !== "/dashboard/admin" ? (
          <Button
            component={NavLink}
            to={"/dashboard/admin"}
            // onClick={() => handleChangeMenuState("/dashboard/files")}
            variant="contained"
            color="warning"
            sx={{ minWidth: 300, mb: 2 }}
            startIcon={<Iconify icon="mdi:arrow-left" />}
          >
            MENÜ
          </Button>
        ) : (
          <Typography variant="h4" gutterBottom>
            Admin Zentrale
          </Typography>
        )}

        <Routes>
          <Route index element={<AdminMainMenu />} />
          {tenants && (
            <Route
              path="overview"
              element={
                <TenantOverview tenants={tenants} userCount={userCount} />
              }
            />
          )}
          <Route path="support" element={<SupportOverview />} />
        </Routes>
      </Container>
    </>
  );
}
