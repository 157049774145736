import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormGroup,
  Grid,
  Divider,
} from "@mui/material";
import { selectCurrentUserData } from "src/features/auth/authSlice";
import {
  useGetUsersNotificationSettingsQuery,
  useUpdateUserNotificationSettingsMutation,
} from "src/features/users/usersApiSlice";
import MySlideSnackbar from "src/components/snackbar/MySlideSnackbar";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";
import { apiSlice } from "src/app/api/apiSlice";

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const currentUserData = useSelector(selectCurrentUserData);

  const { data: notificationSettings, isLoading } =
    useGetUsersNotificationSettingsQuery(currentUserData?.id);

  const [updateUserNotificationSettings] =
    useUpdateUserNotificationSettingsMutation();

  let data = {
    entryCreate: notificationSettings?.entryCreate,
    entryDelete: notificationSettings?.entryDelete,
    entryKontrolle: notificationSettings?.entryKontrolle,
    entryUpdate: notificationSettings?.entryUpdate,
    eventCreate: notificationSettings?.eventCreate,
    eventDayOf: notificationSettings?.eventDayOf,
    eventDelete: notificationSettings?.eventDelete,
    eventUpdate: notificationSettings?.eventUpdate,
    fileCreate: notificationSettings?.fileCreate,
    fileUpdate: notificationSettings?.fileUpdate,
    taskCreate: notificationSettings?.taskCreate,
    taskDelete: notificationSettings?.taskDelete,
    taskUpdate: notificationSettings?.taskUpdate,
  };
  const { handleSubmit, control, reset } = useForm({
    defaultValues: { data },
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onSubmit = async (data) => {
    const result = await updateUserNotificationSettings({
      userId: currentUserData?.id,
      notificationSettings: data,
    });
    if (result) {
      setSnackbarOpen(true);
      dispatch(apiSlice.util.resetApiState());
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Benachrichtigungen
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Hier können Sie einstellen, für welche Ereignisse Sie Benachrichtigungen
        erhalten möchten.
      </Typography>
      <Container maxWidth="ml">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={3} mb={3}>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Task</Typography>
                  <ControlledCheckbox
                    name="taskCreate"
                    control={control}
                    label="Erstellen"
                    defaultChecked={notificationSettings?.taskCreate}
                  />
                  <ControlledCheckbox
                    name="taskUpdate"
                    control={control}
                    label="Aktualisieren"
                    defaultChecked={notificationSettings?.taskUpdate}
                  />
                  <ControlledCheckbox
                    name="taskDelete"
                    control={control}
                    label="Löschen"
                    defaultChecked={notificationSettings?.taskDelete}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Event</Typography>
                  <ControlledCheckbox
                    name="eventCreate"
                    control={control}
                    label="Erstellen"
                    defaultChecked={notificationSettings?.eventCreate}
                  />
                  <ControlledCheckbox
                    name="eventUpdate"
                    control={control}
                    label="Aktualisieren"
                    defaultChecked={notificationSettings?.eventUpdate}
                  />
                  <ControlledCheckbox
                    name="eventDelete"
                    control={control}
                    label="Löschen"
                    defaultChecked={notificationSettings?.eventDelete}
                  />
                  <ControlledCheckbox
                    name="eventDayOf"
                    control={control}
                    label="Tag des Events"
                    defaultChecked={notificationSettings?.eventDayOf}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">
                    Fehler, Beschwerden, Risiken
                  </Typography>
                  <ControlledCheckbox
                    name="entryCreate"
                    control={control}
                    label="Erstellen"
                    defaultChecked={notificationSettings?.entryCreate}
                  />
                  <ControlledCheckbox
                    name="entryUpdate"
                    control={control}
                    label="Aktualisieren"
                    defaultChecked={notificationSettings?.entryUpdate}
                  />
                  <ControlledCheckbox
                    name="entryDelete"
                    control={control}
                    label="Löschen"
                    defaultChecked={notificationSettings?.entryDelete}
                  />
                  <ControlledCheckbox
                    name="entryKontrolle"
                    control={control}
                    label="Kontrolle"
                    defaultChecked={notificationSettings?.entryKontrolle}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Dateien</Typography>
                  <ControlledCheckbox
                    name="fileCreate"
                    control={control}
                    label="Erstellen"
                    defaultChecked={notificationSettings?.fileCreate}
                  />
                  <ControlledCheckbox
                    name="fileUpdate"
                    control={control}
                    label="Aktualisieren"
                    defaultChecked={notificationSettings?.fileUpdate}
                  />
                  <ControlledCheckbox
                    name="readingConfirmation"
                    control={control}
                    label="Lesebestätigung"
                    defaultChecked={notificationSettings?.readingConfirmation}
                    disabled
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
          <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="contained" color="secondary" type="submit">
              Einstellungen speichern
            </Button>
          </Box>
        </form>
        <MySlideSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message="Benachrichtigungseinstellungen aktualisiert"
        />
      </Container>
    </>
  );
};

export default NotificationSettings;
