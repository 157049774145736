// @mui
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  styled,
  Tooltip,
} from "@mui/material";
// utils
// import { fToNow } from "../../../utils/formatTime";
// components
import { useNavigate } from "react-router-dom";
import { serverURL } from "../../../app/api/axios/http.service";
import Iconify from "../../../components/iconify";
import { useTheme } from "@emotion/react";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

// ----------------------------------------------------------------------

// fToDate in german language
export const fToNow = (date) => {
  if (!date) return "";
  return formatDistanceToNow(new Date(date), { addSuffix: true, locale: de });
};

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: 430, // Max-Höhe für die Liste
  overflowY: "auto", // Scrollbar aktivieren

  "&::-webkit-scrollbar": {
    display: "none", // Scrollbar in WebKit-Browsern (Chrome, Safari) ausblenden
  },
  "-ms-overflow-style": "none", // Scrollbar in Internet Explorer und Edge ausblenden
  "scrollbar-width": "none", // Scrollbar in Firefox ausblenden
}));

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <ScrollableBox>
        <Stack
          sx={{
            mt: 1,
          }}
        >
          {list && list.length > 0 ? (
            list.map((news) => <NewsItem key={news._id} news={news} />)
          ) : (
            <Typography variant="body2" color="text.secondary">
              No news available.
            </Typography>
          )}
        </Stack>
      </ScrollableBox>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { _id, imagePath, title, description, postedAt, path, isFromDigitMed } =
    news;

  const getImageSrc = () => {
    if (imagePath === "1") {
      return require("../news/NewsDialogComponents/1.jpg");
    } else if (imagePath === "2") {
      return require("../news/NewsDialogComponents/2.jpg");
    } else if (imagePath === "3") {
      return require("../news/NewsDialogComponents/3.jpg");
    } else if (imagePath === "/assets/images/gesetz.webp") {
      return "/assets/images/gesetz.webp";
    } else {
      const newsId = _id;
      return `${serverURL()}/public/new/image/${newsId}`;
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      onClick={() => navigate(path)}
      sx={{
        pt: 1.5,
        pl: 3,
        pr: 0,
        pb: 1.5,
        cursor: "pointer",
        width: "100%",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <Box
        component="img"
        alt={title}
        src={getImageSrc()}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Link>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>

      {isFromDigitMed && (
        <Tooltip title="Vom DigitMed erstellt">
          <Iconify
            icon="ic:baseline-fact-check" // Beispiel-Icon
            sx={{
              color: theme.palette.secondary.main,
              mr: 1,
              fontSize: "24px",
            }}
          />
        </Tooltip>
      )}

      <Typography
        variant="caption"
        sx={{ pr: 4, flexShrink: 0, color: "text.secondary" }}
      >
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}
