import React from "react";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

function CreateQMStructure() {
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(
      setDialog({
        title: "QM Dokumentenstruktur erstellen",
        type: "CREATE",
        redirect: "/dashboard/files",
        content: "InitializeQMFolder",
      })
    );
  };

  React.useEffect(() => {
    openDialog();
  });

  return <div></div>;
}

export default CreateQMStructure;
