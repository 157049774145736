import * as React from "react";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { RELEASE_DOCUMENT } from "../forms/Forms/defaultValues";
import ReleaseDocumentForm from "../forms/Forms/ReleaseDocumentForm";
import { useReleaseDocumentMutation } from "../../features/fileData/fileDataApiSlice";
import { useDispatch } from "react-redux";
import ErrorAlert from "../alerts/ErrorAlert";
import { uploadFileAxios } from "../../app/api/axios/services/files.service";
import { setDialog } from "../../features/dialog/dialogSlice";
import { Box, Stack } from "@mui/material";

export default function ReleaseDocView({ fileData, documentEditorRef }) {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: RELEASE_DOCUMENT,
  });
  const dispatch = useDispatch();
  const [releaseDocument, { isError }] = useReleaseDocumentMutation();
  const [loading, setLoading] = React.useState(false);

  const revisionChecked = watch("revisionDoctor");
  const checkCreateProof = watch("checkCreateProof");
  const checkReadingConfirmation = watch("readingConfirmation");

  const handleReleaseFile = async (docFormData, formData) => {
    const { selectedFolder } = formData;
    if (selectedFolder) {
      formData.proofFolder = selectedFolder[selectedFolder.length - 1]?.id;
      delete formData.selectedFolder;
    }

    // Handle promises sequentially to avoid partial success issues
    try {
      const resultFileData = await releaseDocument({
        _id: fileData._id,
        ...formData,
      }).unwrap();

      const resultUploadFile = await uploadFileAxios(docFormData, fileData._id);

      return { resultFileData, resultUploadFile };
    } catch (error) {
      // Log and rethrow error to handle it in calling function
      console.error("Error in handleReleaseFile:", error.message);
      throw error;
    }
  };

  const handleSaveResult = (resultFileData, resultUploadFile) => {
    setLoading(false);

    if (resultFileData.type === "success" && resultUploadFile?.status === 200) {
      dispatch(
        setDialog({
          title: `${fileData.fileName} wurde erfolgreich gespeichert!`,
          type: "SUCCESS",
          data: {
            successText: resultFileData?.message,
          },
        })
      );
    } else {
      window.alert(
        "Es gab ein Problem beim Speichern der Datei.\nBitte versuchen Sie es erneut."
      );
    }
  };

  const handleSave = async (formData) => {
    try {
      const exportedDocument =
        await documentEditorRef.current.documentEditor.saveAsBlob("Docx");
      const docFormData = new FormData();
      docFormData.append("fileName", fileData.fileName);
      docFormData.append("file", exportedDocument);

      if (!documentEditorRef.current) {
        setLoading(false);
        return;
      }

      // Sequential handling of operations
      const { resultFileData, resultUploadFile } = await handleReleaseFile(
        docFormData,
        formData
      );

      handleSaveResult(resultFileData, resultUploadFile);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      setLoading(false);
      window.alert(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
      );
    }
  };

  const onSubmit = async (data) => {
    setLoading(true); // Ensure loading state is set correctly
    // return console.log("data.userList :>> ", data.userList);
    try {
      await handleSave(data);
    } catch (error) {
      console.error("Error in onSubmit:", error.message);
    }
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box>
        <form>
          <ReleaseDocumentForm
            control={control}
            revisionChecked={revisionChecked}
            checkCreateProof={checkCreateProof}
            checkReadingConfirmation={checkReadingConfirmation}
          />
        </form>
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {isError && (
          <div style={{ marginTop: 15 }}>
            <ErrorAlert errorMessage="Fehler beim erstellen des Dokuments" />
          </div>
        )}
        <Button
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          color="secondary"
          autoFocus
        >
          {revisionChecked ? "AN ARZT SENDEN" : "FREIGEBEN"}
        </Button>
      </Box>
    </Stack>
  );
}
