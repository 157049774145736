import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import ReleaseDocView from "../ReleaseDocView";

export default function ReleaseFileAdminDialog({
  fileData,
  documentEditorRef,
  loading,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="error"
        sx={{ mr: 2 }}
        disabled={loading}
        onClick={handleClickOpen}
      >
        {loading ? "LADEN..." : "FREIGEBEN"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${fileData.fileName} freigeben`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Wenn Sie das Dokument freigeben, ist es für alle Benutzer verfügbar.
          </Typography>
          <br />
          <Typography>
            Mittels freigegebenen Formblättern können Nachweise erzeugt werden.
          </Typography>
          <br />
          <div>
            <ReleaseDocView
              documentEditorRef={documentEditorRef}
              fileData={fileData}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
