import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { useController } from "react-hook-form";
import { useGetUsersCleanQuery } from "../../features/users/usersApiSlice";
import { selectCurrentUserData } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";

MulitSelectUsersController.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  isOwnNameWithoutShow: PropTypes.bool,
};

export default function MulitSelectUsersController({
  control,
  name,
  label,
  errorMsg,
  fullWidth = true,
  disabled = false,
  helperText,
  required = false,
  isOwnNameWithoutShow = false, // true: own name is required, false: show all names
}) {
  const currentUser = useSelector(selectCurrentUserData);
  const currentUserId = currentUser?.id;
  const { data: users = [], isLoading, isError } = useGetUsersCleanQuery();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const filteredUsers = useMemo(() => {
    return isOwnNameWithoutShow
      ? users.filter((user) => user._id !== currentUserId)
      : users;
  }, [users, currentUserId, isOwnNameWithoutShow]);

  const renderedChips = useMemo(() => {
    if (!field.value || !Array.isArray(field.value)) return null;
    return field.value.map((userId) => {
      const user = users.find((user) => user._id === userId);
      return user ? (
        <Chip key={userId} label={`${user.firstName} ${user.lastName}`} />
      ) : null;
    });
  }, [field.value, users]);

  useEffect(() => {
    if (
      isOwnNameWithoutShow &&
      currentUserId &&
      !field.value.includes(currentUserId)
    ) {
      field.onChange([...field.value, currentUserId]);
    }
  }, [currentUserId, field, isOwnNameWithoutShow]);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading users.</p>;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    field.onChange(Array.isArray(value) ? value : value.split(","));
  };

  return (
    <FormControl fullWidth={fullWidth} error={!!error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...field}
        multiple
        value={Array.isArray(field.value) ? field.value : []}
        onChange={handleChange}
        input={<OutlinedInput id={`${name}-chip`} label={label} />}
        renderValue={() => <div>{renderedChips}</div>}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
              width: 250,
            },
          },
        }}
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.firstName} {user.lastName}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText>{errorMsg || "This field is required."}</FormHelperText>
      )}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
