import React from "react";

import "./HeartBeatLoader.css";

function HeartBeatLoader(props) {
  return (
    <div className="heartbeatloader">
      <svg
        className="svgdraw"
        width="100%"
        height="100%"
        viewBox="0 0 150 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path"
          d="M 0 200 l 40 0 l 5 -40 l 5 40 l 10 0 l 5 15 l 10 -140 l 10 220 l 5 -95 l 10 0 l 5 20 l 5 -20 l 30 0"
          //   d="M1748,1961.4c-418.6-.1-837.3-.1-1255.8.3-25.6,73-135.6,61.1-143.6-16.7-8.9-86.7,110.7-115.5,143.3-35.6l1739,.8,4.1,2.7,53.1,106.6,118.8-944.4,125.6,1014.4,79.7-179.4h493.6c5.4-.9,6.7-9.8,9.7-14.1,52-75.3,164.3-13.6,130.9,69.3-19.5,48.4-85.7,61.4-121.8,23.5-5.4-5.7-12.6-21.6-15.3-24s-1.9-1.7-3.4-1.7l-460.3.3-142.1,324.2-96.6-807.8-88,720.7-118.2-238.8c-150.3-.6-300.6-.2-450.9-.3-.6,0-1.1,0-1.7,0Z"
          fill="transparent"
          stroke-width="4"
          stroke="black"
        />
      </svg>
      <div className="innercircle"></div>
      <div className="outercircle"></div>
    </div>
  );
}

export default HeartBeatLoader;
