import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import EventForm from "./EventForm";
import {
  useAddNewEventMutation,
  useUpdateEventMutation,
} from "../../../features/event/eventApiSlice";
import Iconify from "../../../components/iconify";
import MySnackbar from "./MySnackbar";
import { combineDateAndTime, formatDateTime } from "../../../utils/dateUtils";
import dayjs from "dayjs";
import PopOverComponent from "../../../components/popover/PopOverComponent";

// -------------------------------------------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

EventDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  eventDetails: PropTypes.object,
};
// -------------------------------------------------------------------

export default function EventDialog({ handleClose, eventDetails, eventFlag }) {
  const [addNewEvent] = useAddNewEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  console.log("eventDetails.editTypefdsa", eventDetails?.editType);
  const getDefaultValues = () => {
    const defaultRecurrence = {
      frequency: "daily",
      interval: 1,
      recurrenceEndType: "never",
      endBy: null,
      count: null,
    };

    if (eventFlag === "EDIT") {
      return {
        _id: eventDetails.Id,
        creator: eventDetails.Creator,
        title: eventDetails.Subject || "",
        description: eventDetails.Description || "",
        location: eventDetails.Location || "",
        allDay: eventDetails.IsAllDay || false,
        start: dayjs(eventDetails.StartTime).isValid()
          ? dayjs(eventDetails.StartTime).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        startTime: dayjs(eventDetails.StartTime).isValid()
          ? dayjs(eventDetails.StartTime).format("HH:mm")
          : dayjs().format("HH:mm"),

        end: eventDetails.IsAllDay
          ? dayjs(eventDetails.EndTime).subtract(1, "day").format("YYYY-MM-DD")
          : dayjs(eventDetails.EndTime).format("YYYY-MM-DD"),
        endTime: dayjs(eventDetails.EndTime).isValid()
          ? dayjs(eventDetails.EndTime).format("HH:mm")
          : dayjs().format("HH:mm"),
        user: Array.isArray(eventDetails.User)
          ? eventDetails.User.map((u) => u._id)
          : [],
        color: eventDetails.CategoryColor || "#ffffff",
        isRecurring: eventDetails?.RecurrenceRule ? true : false,
        recurrence: eventDetails.recurrence || defaultRecurrence,
      };
    } else if (eventFlag === "CREATE") {
      return {
        title: eventDetails?.Subject || "",
        description: "",
        location: "",
        allDay: true,
        start: dayjs(eventDetails.StartTime).isValid()
          ? dayjs(eventDetails.StartTime).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        startTime: dayjs(eventDetails.StartTime).isValid()
          ? dayjs(eventDetails.StartTime).format("HH:mm")
          : dayjs().format("HH:mm"),
        end: dayjs(eventDetails.StartTime).isValid()
          ? dayjs(eventDetails.StartTime).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        endTime: dayjs(eventDetails.StartTime).isValid()
          ? dayjs(eventDetails.StartTime).format("HH:mm")
          : dayjs().format("HH:mm"),
        user: [],
        color: "#89cff0",
        isRecurring: eventDetails?.Recurrence ? true : false,
        recurrence: defaultRecurrence,
      };
    } else if (eventFlag === "NEW") {
      return {
        title: "",
        description: "",
        location: "",
        allDay: true,
        start: dayjs().format("YYYY-MM-DD"),
        startTime: dayjs().format("HH:mm"),
        end: dayjs().format("YYYY-MM-DD"),
        endTime: dayjs().format("HH:mm"),
        user: [],
        color: "#89cff0",
        isRecurring: eventDetails?.Recurrence ? true : false,
        recurrence: eventDetails?.Recurrence || defaultRecurrence,
      };
    }
  };

  const { handleSubmit, control, watch, getValues } = useForm({
    defaultValues: getDefaultValues(),
  });

  const currentColor = watch("color");
  const allDay = watch("allDay");
  const isRecurring = watch("isRecurring");

  const onSubmit = async (data) => {
    const { start, startTime, end, endTime, recurrence, isRecurring } = data;

    const startD = dayjs(start).format("YYYY-MM-DD");
    const startDateTime = `${startD}T${startTime}`;
    const endD = dayjs(end).format("YYYY-MM-DD");

    const endDateTime = allDay
      ? eventFlag === "NEW"
        ? dayjs(endD).format("YYYY-MM-DD") + "T" + endTime
        : dayjs(endD).add(1, "day").format("YYYY-MM-DD") + "T" + endTime
      : `${endD}T${endTime}`;

    if (dayjs(startDateTime).isAfter(dayjs(endDateTime))) {
      setSnackbarOpen(true);
      return;
    }

    const updatedRecurrence = isRecurring ? { ...recurrence } : null; // Setze recurrence auf null, wenn nicht wiederkehrend.

    if (updatedRecurrence) {
      if (
        updatedRecurrence.frequency === "weekly" ||
        updatedRecurrence.frequency === "daily"
      ) {
        updatedRecurrence.byMonth = []; // Bei daily/weekly ist byMonth leer
      } else if (updatedRecurrence.frequency === "monthly") {
        updatedRecurrence.byDay = []; // Bei monthly ist byDay leer
      }
    }

    try {
      const result = await addNewEvent({
        ...data,
        start: startDateTime,
        end: endDateTime,
        recurrence: updatedRecurrence,
      });
      console.log("result :>> ", result);
      handleClose("REFETCH");
    } catch (error) {
      console.error("Failed to add new event: ", error);
    }
  };

  const handleUpdate = async () => {
    const formData = {
      ...getValues(),
      _id: getValues()._id,
    };

    const startDateTime = combineDateAndTime(
      formatDateTime(formData.start, "date"),
      formData.startTime
    );
    const endDateTime = allDay
      ? dayjs(formatDateTime(formData.end, "date"))
          .add(1, "day")
          .format("YYYY-MM-DD") +
        "T" +
        formData.endTime
      : combineDateAndTime(
          formatDateTime(formData.end, "date"),
          formData.endTime
        );

    if (dayjs(startDateTime).isAfter(dayjs(endDateTime))) {
      setSnackbarOpen(true);
      return;
    }

    formData.start = startDateTime;
    formData.end = endDateTime;

    try {
      const result = await updateEvent(formData);
      handleClose("REFETCH");
      console.log("Update result:", result);
    } catch (error) {
      console.error("Failed to update event: ", error);
    }
  };

  return (
    <div>
      <FormProvider {...{ handleSubmit, control, watch }}>
        <BootstrapDialog open>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            {eventFlag === "EDIT" && (
              <PopOverComponent
                icon="octicon:three-bars-16"
                dataId={eventDetails.Id}
                art="EVENT"
                creator={eventDetails.Creator}
              />
            )}
            <Typography
              id="customized-dialog-title"
              sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            >
              {eventFlag === "EDIT"
                ? "Event anschauen / bearbeiten"
                : "Neues Event erstellen"}
            </Typography>
            <Box sx={{ flex: "0 0 auto" }}>
              <Iconify
                onClick={handleClose}
                icon="mingcute:close-line"
                style={{ fontSize: "50px", color: "action.active" }}
                sx={{
                  transition: "transform 0.1s", // Smooth transition for transform
                  "&:hover": {
                    transform: "scale(1.8)", // Scale up the icon when hovered
                  },
                }}
              />
            </Box>
          </Box>

          <DialogContent dividers>
            <EventForm
              control={control}
              allDay={allDay}
              isRecurring={isRecurring}
              watch={watch}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              variant="contained"
              style={{ background: "#9E9E9E" }}
            >
              Abbrechen
            </Button>
            {eventFlag !== "EDIT" ? (
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                style={{ background: currentColor }}
              >
                Erstellen
              </Button>
            ) : (
              <Button
                onClick={() => handleUpdate()}
                variant="contained"
                style={{ background: currentColor }}
              >
                Aktualisieren
              </Button>
            )}
          </DialogActions>
        </BootstrapDialog>
      </FormProvider>

      <MySnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Enddatum ist älter als das Anfangsdatum!"
      />
    </div>
  );
}
