import React, { useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { useController } from "react-hook-form";
import { Icon } from "@iconify/react";

const SelectUsersAreasOffices = ({ control, offices, users }) => {
  const { field } = useController({
    name: "userList",
    control,
    defaultValue: [], // Default to an empty array of objects
  });

  // Combine data into a unified list and maintain order
  const [leftItems, setLeftItems] = useState(() => {
    const allItems = [
      { type: "all", name: "Alle Mitarbeiter", _id: "ALLE" },
      ...offices.flatMap((office) => [
        { type: "office", name: office.officeName, _id: office._id },
        ...office.area.map((area) => ({
          type: "area",
          name: `${office.officeName}: ${area.name}`,
          officeId: `${office._id}`,
          _id: area._id,
        })),
      ]),
      ...users.map((user) => ({
        type: "user",
        name: `${user.firstName} ${user.lastName}`,
        _id: user._id,
      })),
    ];
    return allItems;
  });

  const [rightItems, setRightItems] = useState([]);

  const moveToRight = (item) => {
    setLeftItems((prev) => prev.filter((i) => i._id !== item._id));
    setRightItems((prev) => [...prev, item]);
    field.onChange([...field.value, item]); // Add full item object to the field value
  };

  const moveToLeft = (item) => {
    setRightItems((prev) => prev.filter((i) => i._id !== item._id));
    setLeftItems((prev) => [...prev, item]);
    field.onChange(field.value.filter((obj) => obj._id !== item._id)); // Remove item object from the field value
  };

  const renderChips = (items, moveHandler, color) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      {items.map((item) => (
        <Chip
          key={item._id}
          avatar={
            <Avatar>
              {item.type === "area" && <Icon icon="mdi:account-group" />}
              {item.type === "office" && (
                <Icon icon="fa-solid:clinic-medical" />
              )}
              {item.type === "user" && <Icon icon="mdi:user" />}
              {item.type === "all" && <Icon icon="solar:hospital-bold" />}
            </Avatar>
          }
          label={item.name}
          onClick={() => moveHandler(item)}
          color={color}
          variant="outlined"
        />
      ))}
    </Box>
  );

  const sortItems = (items) => {
    return items.sort((a, b) => {
      const typeOrder = ["all", "office", "area", "user"];
      return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom>
          Benutzer für Lesebestätigung auswählen:
        </Typography>
      </Grid>
      {/* Left Table */}
      <Grid item xs={12} md={6}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Verfügbar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(leftItems).map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    {renderChips([item], moveToRight, "primary")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Right Table */}
      <Grid item xs={12} md={6}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Ausgewählt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(rightItems).map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    {renderChips([item], moveToLeft, "secondary")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SelectUsersAreasOffices;
