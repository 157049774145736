import React from "react";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import FolderSelector from "../../../../components/forms/Forms/CreateFileDataForm/FolderSelector";

function SelectDirectory({ qmFolders, onSubmit }) {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {},
  });

  const selectedFolder = watch("selectedFolder");

  const handleSelectFolder = async (data) => {
    const { selectedFolder } = data;
    if (selectedFolder[selectedFolder.length - 1]?.id) {
      onSubmit(selectedFolder[selectedFolder.length - 1]?.id);
    }
  };

  return (
    <div>
      <div>
        <Typography variant="h4">
          Aktuell ist es nur möglich QM Dokumente hochzuladen.
        </Typography>
      </div>
      <div>
        <Typography variant="h4">
          Bitte wählen Sie das Verzeichnis aus, in das die Datei hochgeladen
          werden soll:
        </Typography>
      </div>
      <div>
        Es ist wichtig, Dateien bzw. Dokumente im Qualitätsmanagement
        ordnungsgemäß zu speichern, um eine strukturierte Organisation und
        schnelle Auffindbarkeit zu gewährleisten. Dies fördert die Effizienz,
        erleichtert die Zusammenarbeit und entspricht den Qualitätsstandards für
        die Dokumentation im Gesundheitswesen.
      </div>
      <form>
        <div style={{ marginTop: 10, display: "flex" }}>
          <Typography sx={{ pt: 2, pr: 5 }} variant="h5">
            Bitte Hauptordner wählen:
          </Typography>
          <div>
            <Controller
              name="selectedFolder"
              required
              control={control}
              render={({ field }) => (
                <FolderSelector
                  folderStructure={qmFolders}
                  control={control}
                  name="selectedFolder"
                  infoText="Bitte wählen Sie einen Ordner in dem das neue Dokument abgelegt
              werden soll."
                  required={true}
                />
              )}
            />
          </div>
        </div>
        <Button
          onClick={handleSubmit(handleSelectFolder)}
          variant="contained"
          fullWidth
          disabled={!selectedFolder}
        >
          Weiter
        </Button>
      </form>
    </div>
  );
}

SelectDirectory.propTypes = { onSubmit: PropTypes.func };

export default SelectDirectory;
