import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import { useGetUsersCleanQuery } from "../../../features/users/usersApiSlice";
import Iconify from "../../iconify";

function FileDataCreatorSelect({ control }) {
  const currentUserData = useSelector(selectCurrentUserData);
  const { data: users } = useGetUsersCleanQuery();

  const assignToMe = (e, onChange) => {
    e.preventDefault();
    onChange(currentUserData?.id);
  };

  const createSelectValueArrays = () => {
    let a = users.map((user) => ({
      label: `${user?.firstName} ${user?.lastName}`,
      value: user?._id,
    }));
    return a.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <Controller
      name="creator"
      control={control}
      rules={{
        required: false,
      }}
      render={({ field, field: { onChange }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel>Erstellt von</InputLabel>
          <Select
            {...field}
            label="Erstellt von"
            variant="outlined"
            error={error && true}
          >
            <MenuItem value="" disabled>
              Benutzer auswählen
            </MenuItem>
            {users && createSelectValueArrays()}
          </Select>
          <FormHelperText error>
            {error && "Bitte Benutzer auswählen"}
          </FormHelperText>
          <Button
            variant="text"
            onClick={(e) => assignToMe(e, onChange)} // Ruft assignToMe mit onChange auf
            startIcon={
              <Iconify
                icon="clarity:assign-user-line"
                style={{ color: "blue", fontSize: "24px" }}
              />
            }
          >
            Mir zuweisen
          </Button>
        </FormControl>
      )}
    />
  );
}

FileDataCreatorSelect.propTypes = {
  control: PropTypes.object,
};

export default FileDataCreatorSelect;
