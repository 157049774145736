import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Container, Typography } from "@mui/material";
// components
// sections
import {
  AppNewsUpdate,
  AppCurrentVisits,
  AppCalendarList,
  AppSupport,
} from "../sections/@dashboard/app";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserData } from "../features/auth/authSlice";
import { setDialog } from "../features/dialog/dialogSlice";
import { useGetDashboardDataQuery } from "src/features/dashboard/dashboardApiSlice";
import { useGetEventsQuery } from "src/features/event/eventApiSlice";
import AppWidgetFourSummary from "src/sections/@dashboard/app/AppWidgetFourSummary";
import AppUpdateList from "../sections/@dashboard/app/AppUpdateList";
import ReadingConfirmationWidget from "../sections/@dashboard/app/ReadingConfirmationWidget/ReadingConfirmationWidget";

// ----------------------------------------------------------------------

export default function DashboardPage() {
  const { data } = useGetDashboardDataQuery();

  const { data: events } = useGetEventsQuery();

  const userData = useSelector(selectCurrentUserData);

  const dispatch = useDispatch();

  const handleOpenWelcomeDialog = () => {
    dispatch(
      setDialog({
        title: "Neue Praxis erstellen",
        type: "CREATE",
        content: "welcome",
        data: userData,
      })
    );
  };

  if (userData && userData.initialLogin) {
    handleOpenWelcomeDialog();
  }

  return (
    <>
      <Helmet>
        <title> Digit QM | Dashboard </title>
      </Helmet>

      <Container sx={{ minHeight: "100%" }} maxWidth="xl">
        <Typography variant="h2" sx={{ ml: 2 }}>
          Willkommen zurück!
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          fontWeight="bold"
          sx={{ ml: 2, mb: 2 }}
        >
          Ihre Praxis, perfekt organisiert – jeden Tag.
        </Typography>
        <Grid container spacing={3}>
          <Grid container spacing={4} sx={{ mt: 1, ml: 1 }}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <AppWidgetFourSummary
                parts={[
                  {
                    title: "Neue Aufgaben",
                    total: data?.tasks?.newTask || 0,
                    icon: "material-symbols:star-outline",
                    color: "#e1ecf0",
                  },
                  {
                    title: "Aufgaben in Bearbeitung",
                    total: data?.tasks?.editTask || 0,
                    icon: "ep:setting",
                    color: "#e8e8e8",
                  },
                  {
                    title: "Aufgaben in Überprüfung",
                    total: data?.tasks?.reviewTask || 0,
                    icon: "bx:revision",
                    color: "#b2ebf2",
                  },
                  {
                    title: "Erledigte Aufgaben",
                    total: data?.tasks?.doneTask || 0,
                    icon: "eva:done-all-fill",
                    color: "#c9f1c9",
                  },
                ]}
                routeTo="/dashboard/task"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <AppWidgetFourSummary
                parts={[
                  {
                    title: "Neue Dokumente",
                    total: data?.fileDataStatistics?.newDocuments || 0,
                    icon: "ic:round-insert-drive-file",
                    color: "#facedg",
                  },
                  {
                    title: "Dokumente in Bearbeitung",
                    total: data?.fileDataStatistics?.editDocuments || 0,
                    icon: "fluent:document-edit-20-filled",
                    color: "#b3e5fc",
                  },
                  {
                    title: "Dokumente in Prüfung",
                    total: data?.fileDataStatistics?.reviewDocuments || 0,
                    icon: "ic:baseline-assignment",
                    color: "#ffe0b2",
                  },
                  {
                    title: "Freigegebene Dokumente",
                    total: data?.fileDataStatistics?.releasedDocuments || 0,
                    icon: "ic:baseline-verified",
                    color: "#ffcdd2",
                  },
                ]}
                routeTo="/dashboard/files"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {data?.fileDataStatistics && (
                <ReadingConfirmationWidget
                  title="Übersicht offene Lesebestätigungen"
                  fileDataStatistics={data?.fileDataStatistics}
                />
              )}
            </Grid>
          </Grid>

          {/* <Grid container spacing={4} sx={{ mt: 1, ml: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Neue Aufgaben"
                total={data?.tasks?.newTask || 0}
                icon={"material-symbols:star-outline"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Aufgaben in Bearbeitung"
                total={data?.tasks?.editTask || 0}
                color="info"
                icon={"ep:setting"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Aufgaben in Überprüfung"
                total={data?.tasks?.reviewTask || 0}
                color="warning"
                icon={"bx:revision"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Erledigte Aufgaben"
                total={data?.tasks?.doneTask || 0}
                color="error"
                icon={"eva:done-all-fill"}
              />
            </Grid>
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="Wichtige Mitteilungen - Neuigkeiten, Gesetzestexte"
              list={data?.combinedData}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCalendarList title="Termine" events={events} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppSupport />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {data?.errorOrComplaint && (
              <AppCurrentVisits
                title="Fehler, Beschwerden & Risiken"
                chartData={[
                  { label: "Fehler", value: data?.errorOrComplaint?.fehler },
                  {
                    label: "Beschwerden",
                    value: data?.errorOrComplaint?.beschwerde,
                  },
                  { label: "Risiken", value: data?.errorOrComplaint?.risiko },
                ]}
                chartColors={[
                  "#d9f3f2",
                  "#aee3e4",
                  "#48aead",
                  // theme.palette.warning.main,
                  // theme.palette.primary.main,
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppUpdateList
              title="Nächste Updates"
              subheader="Geplante Verbesserungen und Features"
              tasks={[
                {
                  id: 5,
                  title: "Video-Tutorials für jede Seite",
                  description:
                    "Bereitstellung von Videoanleitungen für jede Seite, um den Benutzern die Bedienung der Software zu erleichtern.",
                  type: "feature",
                },
                {
                  id: 6,
                  title: "Organigramm-Funktion",
                  description:
                    "Implementierung eines Organigramms, das die Unternehmensstruktur und Hierarchien abbildet.",
                  type: "feature",
                },
                {
                  id: 1,
                  title: "Zeiterfassungssystem",
                  description:
                    "Einführung eines Zeiterfassungssystems zur Verfolgung von Arbeitsstunden und Pausenzeiten.",
                  type: "feature",
                },
                {
                  id: 2,
                  title: "Urlaubsantragssystem",
                  description:
                    "Integration eines digitalen Urlaubsantragssystems zur schnellen und einfachen Bearbeitung von Urlaubsanträgen.",
                  type: "feature",
                },
                {
                  id: 3,
                  title: "Dark-Mode-Implementierung",
                  description:
                    "Erweiterung des Dark-Modes für alle Seiten, um die Benutzerfreundlichkeit zu verbessern.",
                  type: "feature",
                },
                {
                  id: 4,
                  title: "Mehrsprachige Unterstützung",
                  description:
                    "Hinzufügen von Unterstützung für mehrere Sprachen, um die App in verschiedenen Ländern verfügbar zu machen.",
                  type: "improvement",
                },
              ]}
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Risikomanagement"
              // subheader="Dies ist nur ein Beispiel"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  "Risikoerkennung",
                  "Risikoanalyse",
                  "Risikoplanung",
                  "Risikobewertung",
                  "Risikoüberwachung",
                ][index],
                text: [
                  "Identifikation potenzieller Risiken",
                  "Bewertung potenzieller Risiken",
                  "Erstellung eines Risiko-Vermeidungsplans & Notfallplans",
                  "Priorisierung der Risiken",
                  "Laufende Überprüfung & Anpassung der Pläne",
                ][index],
                type: `order${index + 1}`,
              }))}
            />
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Aufgabenanalyse"
              subheader="(+10%) offene Aufgaben im Vergleich vom letzten Monat"
              chartLabels={[
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ]}
              chartData={[
                {
                  name: "offen",
                  type: "column",
                  fill: "solid",
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: "In Bearbeitung",
                  type: "area",
                  fill: "gradient",
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: "In Überprüfung",
                  type: "line",
                  fill: "solid",
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
                {
                  name: "Erledigt",
                  type: "line",
                  fill: "solid",
                  data: [1, 15, 46, 20, 54, 54, 46, 26, 5, 6, 13],
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
            title="Conversion Rates"
            subheader="(+43%) than last year"
            chartData={[
              { label: "Italy", value: 400 },
              { label: "Japan", value: 430 },
              { label: "China", value: 448 },
              { label: "Canada", value: 470 },
              { label: "France", value: 540 },
              { label: "Germany", value: 580 },
                { label: "South Korea", value: 690 },
                { label: "Netherlands", value: 1100 },
                { label: "United States", value: 1200 },
                { label: "United Kingdom", value: 1380 },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Abteilung - Aufgaben"
              chartLabels={["Verwaltung", "Wartezimmer", "test", "Geography"]}
              chartData={[
                { name: "Series 1", data: [3, 5, 2, 8] },
                { name: "Series 2", data: [5, 13, 3, 10] },
                { name: "Series 3", data: [4, 1, 6, 13] },
                { name: "Series 4", data: [24, 10, 2, 1] },
                { name: "Series 5", data: [4, 12, 16, 3] },
              ]}
              chartColors={[...Array(6)].map(
                () => theme.palette.text.secondary
              )}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Wie bearbeite ich eine Datei?"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  "Button, Neues Dokument",
                  "Datei einfügen",
                  "Datei bearbeiten",
                  "Nötigen Felder ausfüllen",
                  "Richtigen Pfad korrigieren",
                ][index],
                type: `order${index + 1}`,
                // time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: "FaceBook",
                  value: 323234,
                  icon: (
                    <Iconify
                      icon={"eva:facebook-fill"}
                      color="#1877F2"
                      width={32}
                    />
                  ),
                },
                {
                  name: "Google",
                  value: 341212,
                  icon: (
                    <Iconify
                      icon={"eva:google-fill"}
                      color="#DF3E30"
                      width={32}
                    />
                  ),
                },
                {
                  name: "Linkedin",
                  value: 411213,
                  icon: (
                    <Iconify
                      icon={"eva:linkedin-fill"}
                      color="#006097"
                      width={32}
                    />
                  ),
                },
                {
                  name: "Twitter",
                  value: 443232,
                  icon: (
                    <Iconify
                      icon={"eva:twitter-fill"}
                      color="#1C9CEA"
                      width={32}
                    />
                  ),
                },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
