import React from "react";
import { Box, Typography } from "@mui/material";
import TextField from "../../../../components/forms/TextField";
import MulitSelectUsersController from "../../../../components/forms/MultiSelectUsersController";

export default function CreateGroupForm({ control }) {
  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Wähle einen Benutzer aus, um einen Chat zu starten:
      </Typography>
      <TextField
        control={control}
        label="Gruppenname"
        name="groupName"
        fullWidth
        required
        sx={{ mb: 2 }}
      />

      <MulitSelectUsersController
        control={control}
        name="users"
        label="Personen"
        required={true}
        fullWidth
        isOwnNameWithoutShow={true}
      />

      <Box sx={{ mt: 2 }}>
        <TextField
          control={control}
          minRows={3}
          required={true}
          multiline
          label="Schreiben Sie eine Nachricht..."
          name="message"
          fullWidth
        />
      </Box>
    </>
  );
}
