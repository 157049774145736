import React, { useEffect, useState } from "react";
import Logo from "../components/logo/Logo";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import PublicSurveyForm from "../components/forms/Forms/PublicSurveyForm";
import {
  answerPublicSurvey,
  getPublicSurveyQuestions,
} from "../app/api/axios/services/public.service";
import Loading from "../components/spinners/Loading";
import { serverURL } from "../app/api/axios/http.service";
import PublicSurveyUploadSuccess from "../sections/@dashboard/survey/PublicSurveyUploadSuccess";
import { Helmet } from "react-helmet-async";

function PublicSurveyPage() {
  const [answers, setAnswers] = useState({}); // Store user's answers
  const hasFetched = React.useRef(false);

  // State to store survey data
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Get the userId param from the URL.
  const { surveyId } = useParams();

  const handleAnswerSurvey = async () => {
    setLoading(true);
    console.log("answers :>> ", answers);
    console.log("survey :>> ", survey);

    try {
      let result = await answerPublicSurvey(surveyId, answers);
      if (result.status === 200) {
        // Answer was submitted successfully
        // Store a cookie so that the user can not send multiple answers for one survey
        const submissionData = {
          id: surveyId,
          submitted: true,
          timestamp: new Date().toISOString(), // Optional: add a timestamp if needed
        };
        // Convert the object to a JSON string
        // Add flag to survey to check if this should be done
        if (survey?.protection) {
          localStorage.setItem(
            `surveySubmission_${surveyId}`,
            JSON.stringify(submissionData)
          );
        }

        // Show user success component
        setSurvey("UPLOADED");
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch survey questions
  useEffect(() => {
    // Check if user has already send this survey
    const savedSubmission = localStorage.getItem(
      `surveySubmission_${surveyId}`
    );

    if (savedSubmission) {
      const submissionData = JSON.parse(savedSubmission);
      if (submissionData.id === surveyId) {
        // Survey was already filled out before. Render Successtext.
        // console.log(submissionData.id); // '66e2ce24177b944d3b91b149'
        // console.log(submissionData.submitted); // true
        // console.log(submissionData.timestamp); // e.g., '2024-10-30T12:34:56.789Z'
        setSurvey("UPLOADED");
        setLoading(false);
        return;
      }
      // found savedSubmission but from a different survey
    }

    if (hasFetched.current) return;
    const fetchSurvey = async () => {
      try {
        setLoading(true); // Start loading
        const response = await getPublicSurveyQuestions(surveyId);
        setSurvey(response.data.survey); // Store the survey data in state
      } catch (err) {
        setError("Failed to load survey");
        console.error("Error fetching survey:", err);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    // Only fetch data if dialog.data.fileData._id exists
    if (surveyId) {
      fetchSurvey(); // Call the fetch function when the component mounts
    }
    hasFetched.current = true; // Set the flag to true after the effect runs
  }, [surveyId]);

  const handleAnswer = (index, value) => {
    setAnswers((prev) => ({
      ...prev,
      [index]: { value, index },
    }));
  };

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error) return <div>{error}</div>;

  if (survey === "UPLOADED") {
    return (
      <div>
        <Helmet>
          <title> Digit QM | Vielen Dank für die Teilnahme</title>
        </Helmet>
        <PublicSurveyUploadSuccess />;
      </div>
    );
  }

  if (survey) {
    return (
      <Box sx={{ ml: 3 }}>
        <Helmet>
          <title> Digit QM | Befragung</title>
        </Helmet>
        <div style={{ margin: 5 }}>
          <Logo
            disabledLink
            logoPath={`${serverURL()}/public/tenant/image/${
              survey?.tenant ?? " "
            }`}
            sx={{ width: "20%", height: "auto" }}
          />
          {/* <Box
                component="img"
                src={`${serverURL()}/public/tenant/image/${
                  currentUserData?.tenant ?? " "
                }`}
                alt="Praxis Logo"
                sx={{
                  cursor: "pointer",
                  maxHeight: 75,
                }}
              /> */}
        </div>
        <Typography textAlign={"left"} variant="h1">
          Befragung
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "125%" }, py: 2 }}
          textAlign={"left"}
          variant="h2"
        >
          {survey.surveyTitle}
        </Typography>

        <Typography sx={{ p: 1 }} variant="body1" align="left" gutterBottom>
          {survey.surveyDescription}
        </Typography>
        <div>
          <form>
            <PublicSurveyForm
              survey={survey}
              answers={answers}
              handleAnswer={handleAnswer}
            />
          </form>
          <Box display="flex" justifyContent="left" mt={4}>
            <Button
              variant="contained"
              sx={{ minWidth: 300 }}
              color="primary"
              onClick={handleAnswerSurvey}
            >
              ABSENDEN
            </Button>
          </Box>
        </div>
      </Box>
    );
  }
}

PublicSurveyPage.propTypes = {};

export default PublicSurveyPage;
