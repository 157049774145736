// import React, { createContext, useContext, useEffect, useState } from "react";
// import { initializeSocket } from "./socket"; // Stelle sicher, dass dies korrekt ist
// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "../../features/auth/authSlice";
// import { useRefreshMutation } from "../../features/auth/authApiSlice";

// // Socket Context erstellen
// const SocketContext = createContext(null);

// // Socket Provider
// export const SocketProvider = ({ children }) => {
//   const currentToken = useSelector(selectCurrentToken);
//   const [socket, setSocket] = useState(null);
//   const [refresh] = useRefreshMutation();

//   useEffect(() => {
//     if (currentToken) {
//       const socketInstance = initializeSocket(currentToken);
//       setSocket(socketInstance);

//       socketInstance.on("connect", () => {
//         console.log("Socket verbunden mit ID:", socketInstance.id);
//       });

//       socketInstance.on("disconnect", async (reason) => {
//         console.log("Verbindung getrennt:", reason);

//         if (reason === "io server disconnect" || reason === "transport close") {
//           console.log("Versuche, das Token zu aktualisieren...");
//           try {
//             const { data } = await refresh().unwrap();
//             const newSocket = initializeSocket(data.token);
//             setSocket(newSocket);
//           } catch (error) {
//             console.error("Fehler beim Aktualisieren des Tokens:", error);
//           }
//         }
//       });

//       // Cleanup bei Unmount
//       return () => {
//         socketInstance.disconnect();
//       };
//     }
//   }, [currentToken]);

//   return (
//     <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
//   );
// };

// // Socket Hook für Komponenten
// export const useSocket = () => {
//   return useContext(SocketContext);
// };

import React, { createContext, useContext, useEffect, useState } from "react";
import { initializeSocket } from "./socket"; // Stelle sicher, dass dies korrekt ist
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import { useRefreshMutation } from "../../features/auth/authApiSlice";

// Socket Context erstellen
const SocketContext = createContext(null);

// Socket Provider
export const SocketProvider = ({ children }) => {
  const currentToken = useSelector(selectCurrentToken);
  const [socket, setSocket] = useState(null);
  const [refresh] = useRefreshMutation();

  useEffect(() => {
    if (currentToken) {
      const socketInstance = initializeSocket(currentToken);
      setSocket(socketInstance);

      const handleReconnect = () => {
        console.log("Erneute Verbindung versucht...");
        if (!socketInstance.connected) {
          socketInstance.connect(); // Erneut versuchen zu verbinden
        }
      };

      const handleDisconnect = async (reason) => {
        console.log("Verbindung getrennt:", reason);

        // Automatische Wiederverbindung aktivieren
        if (reason === "io server disconnect" || reason === "transport close") {
          console.log("Versuche, erneut zu verbinden...");
          handleReconnect();

          // Falls die Wiederverbindung fehlschlägt, Token aktualisieren
          if (!socketInstance.connected) {
            console.log(
              "Erneute Verbindung fehlgeschlagen. Token aktualisieren..."
            );
            try {
              const { data } = await refresh().unwrap();
              const newSocket = initializeSocket(data.token);
              setSocket(newSocket);
            } catch (error) {
              console.error("Fehler beim Aktualisieren des Tokens:", error);
            }
          }
        }
      };

      socketInstance.on("connect", () => {
        console.log("Socket verbunden mit ID:", socketInstance.id);
      });

      socketInstance.on("disconnect", handleDisconnect);

      // Cleanup bei Unmount
      return () => {
        socketInstance.disconnect();
      };
    }
  }, [currentToken]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

// Socket Hook für Komponenten
export const useSocket = () => {
  return useContext(SocketContext);
};
