import * as React from "react";
import { useForm } from "react-hook-form";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom/dist";
import ErrorAlert from "../components/alerts/ErrorAlert";
import RegisterForm from "../sections/auth/registration/RegisterForm";
import RegisterSuccess from "../sections/auth/registration/RegisterSuccess";
import { useInitializeMutation } from "../features/auth/registerApiSlice";
import { Button, Divider } from "@mui/material";
import TenantForm from "../sections/auth/registration/TenantForm";
import Loading from "../components/spinners/Loading";
import { Helmet } from "react-helmet-async";
import Iconify from "../components/iconify";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.digitmed.de/">
        Digit Med
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const defVal = {
  mail: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  birthdate: new Date(),
  checkPassword: "",
};

export default function RegisterPage() {
  const { handleSubmit, control, watch, setError } = useForm({
    defaultValues: defVal,
  });
  const [loading, setLoading] = React.useState(false);
  const [registered, setRegistered] = React.useState(false);

  const [initialize, { isLoading }] = useInitializeMutation();

  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleRegister = async (data) => {
    setLoading(true);
    try {
      await initialize(data).unwrap();
      setRegistered("SUCCESS");
      setLoading(false);
    } catch (error) {
      console.log("error :>> ", error);
      if (error?.data?.message === "Tenantname is required.") {
        setError("tenantName", {
          type: "custom",
          message: "Bitte einen Namen eintragen.",
        });
      }

      if (error?.data?.message === "E-Mail Adresse ist bereits in Benutzung.") {
        setError("mail", {
          type: "custom",
          message: "E-Mail Adresse ist bereits in Benutzung.",
        });
      } else {
        setRegistered("FAIL");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    setRegistered(false);
    delete data.checkPassword;
    data.username = data.mail;
    handleRegister(data);
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  if (registered === "SUCCESS") {
    return <RegisterSuccess close={() => setRegistered(false)} />;
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Helmet>
        <title> Digit QM | Registrieren</title>
      </Helmet>
      {(loading || isLoading) && <Loading backdrop={true} />}
      <CssBaseline />

      <Grid
        item
        xs={false}
        sm={2}
        md={5}
        sx={{
          backgroundImage: "url(/assets/images/med-utils.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      />
      <Grid
        item
        xs={12}
        sm={10}
        md={7}
        component={Paper}
        elevation={6}
        square
        sx={{ maxHeight: "100%", overflow: "auto" }}
      >
        <Box
          sx={{
            mt: 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "100%",
          }}
        >
          <Typography align="center" variant="h4" gutterBottom>
            Registrieren
          </Typography>
          <Box style={{ minWidth: 350 }} sx={{ mt: 0 }}>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <Divider orientation="horizontal" sx={{ m: 3 }} flexItem />
              <Typography sx={{ my: 3 }} align="left" variant="h5">
                Hauptverantwortliche Person
              </Typography>
              <RegisterForm control={control} watch={watch} />
              <Divider orientation="horizontal" sx={{ m: 3 }} flexItem />
              <Typography sx={{ my: 3 }} align="left" variant="h5">
                Geschäftsstelle / Hauptpraxis
              </Typography>
              <TenantForm control={control} />
              <Divider orientation="horizontal" sx={{ m: 3 }} flexItem />
              {registered === "FAIL" && (
                <ErrorAlert errorMessage="Problem beim Initialisieren, bitte erneut versuchen." />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{
                  mt: 2,
                  mb: 3,
                  fontSize: "125%",
                  backgroundColor: "#0097a5",
                  "&:hover": {
                    backgroundColor: "#035e66",
                  },
                }}
              >
                REGISTRIEREN
              </Button>
              <Button
                component={NavLink}
                to={"/dashboard/app"}
                variant="contained"
                startIcon={<Iconify icon="material-symbols:home-outline" />}
              >
                Zurück
              </Button>
            </form>
            <Copyright sx={{ my: 2 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
