import React, { useEffect, useState } from "react";
// @mui
import { Badge, IconButton } from "@mui/material";
// components
import Iconify from "../../../../components/iconify";
import { useGetNotesQuery } from "../../../../features/notes/notesApiSlice";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { data } = useGetNotesQuery();
  const navigate = useNavigate();

  // States for notes
  const [notesNotRead, setNotesNotRead] = useState([]);

  // Update notes when data changes
  useEffect(() => {
    if (data) {
      setNotesNotRead(data.notesNotRead);
    } else {
      setNotesNotRead([]);
    }
  }, [data]);

  // Total unread count
  const totalUnRead = notesNotRead?.length || 0;

  return (
    <>
      <IconButton
        onClick={() => navigate("/dashboard/notifications")}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>
    </>
  );
}
