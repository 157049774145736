import React from "react";
// @mui
import { Button, Stack } from "@mui/material";
import Iconify from "../../../components/iconify";
import { NavLink } from "react-router-dom";

function AdminMainMenu() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{ minHeight: "100%", py: 3 }}
    >
      <Button
        component={NavLink}
        to={"/register"}
        variant="contained"
        sx={{ minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="mdi:file-document-plus-outline" />}
      >
        Neue Hauptpraxis
      </Button>
      <Button
        component={NavLink}
        to={"overview"}
        variant="contained"
        sx={{ minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="gala:menu-left" />}
      >
        Hauptpraxen Übersicht
      </Button>
      <Button
        component={NavLink}
        to={"support"}
        variant="contained"
        sx={{ minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="gala:menu-left" />}
      >
        Supportanfragen{" "}
      </Button>
    </Stack>
  );
}

export default AdminMainMenu;
