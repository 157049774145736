import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import ControlledRadioButton from "../../../../components/forms/ControlledRadioButton";
import Iconify from "../../../../components/iconify"; // Importiere die Iconify-Komponente

VisibilityForm.propTypes = {
  control: PropTypes.object.isRequired,
};

export default function VisibilityForm({ control, status }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={5.5} sx={{ ml: 2 }}>
          <ControlledRadioButton
            control={control}
            name="visibilityScope"
            label="Geltungsbereich"
            required={true}
            disabled={status === "SuperAdmin"}
            itemArray={[
              {
                label: "Für alle Praxen",
                value: "tenant",
              },
              {
                label: "Nur diese Praxis",
                value: "office",
              },
            ]}
            tooltip={{
              title: "Erklärung - Geltungsbereich",
              content:
                "Wenn Sie den Gesetzeseintrag auf 'Für alle Praxen' setzen, ist dieser für alle Mitarbeiter aller Praxen sichtbar. Im Modus 'Nur diese Praxis' können nur die Mitarbeiter dieser Praxis den Gesetzeseintrag sehen.",
              icon: "clarity:help-solid",
            }}
          />
        </Grid>
      </Grid>

      {/* Neue Frage "Zu den Favoriten hinzufügen?" */}
      <Grid item xs={12}>
        <Grid container sx={{ ml: 2 }}>
          <Grid item xs={12}>
            <ControlledRadioButton
              control={control}
              name="favorite"
              label="Zu den Favoriten hinzufügen?"
              required={true}
              itemArray={[
                {
                  label: (
                    <Box display="flex" alignItems="center">
                      Ja
                      <Iconify
                        icon="mdi:star"
                        width={20} // Größe erhöhen
                        height={20} // Größe erhöhen
                        sx={{
                          ml: 1,
                          color: "gold", // Kräftigere Farbe verwenden
                          "&:hover": {
                            color: "orange", // Farbe bei Hover ändern
                            transform: "scale(1.1)", // Vergrößern bei Hover
                            transition: "0.2s ease", // Übergangseffekt
                          },
                        }}
                      />
                    </Box>
                  ),
                  value: "true",
                },
                {
                  label: "Nein",
                  value: "false",
                },
              ]}
              tooltip={{
                title: "Erklärung",
                content:
                  "Wenn Sie 'Ja' auswählen, wird dieser Gesetzeseintrag auf dem Dashboard angezeigt.",
                icon: "clarity:help-solid",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
