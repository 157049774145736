import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import ProgressMobileStepper from "../../../components/stepper/ProgressMobileStepper";
import SelectFileStep from "./FileUploadDialogSteps/SelectFileStep";
import { getFileDataFromName } from "../../../utils/fileUtils";
import SelectDocumentDataStep from "./SelectDocumentDataStep";
import { useGetFileStructureQuery } from "../../../features/files/filesApiSlice";
import WorkFlowStep from "./FileUploadDialogSteps/WorkFlowStep";
import SummaryStep from "./FileUploadDialogSteps/SummaryStep";
import UploadFileStep from "./FileUploadDialogSteps/UploadFileStep";
import SuccessStep from "./FileUploadDialogSteps/SuccessStep";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const steps = [
  "Dokument hochladen",
  "Dokumentart und Bereich wählen",
  "Dokumentdetails",
  "Übersicht",
  "Dokument wird hochgeladen",
  "Dokument erfolgreich hochgeladen",
];

function FileUploadDialog({ handleClose }) {
  const { data: fileStructure } = useGetFileStructureQuery();

  console.log("fileStructure :>> ", fileStructure);

  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    docType: null,
    directory: null,
    allowNext: false,
    file: null,
    fileData: null,
    fileDetails: null,
    qmFolders: null,
  });

  const handleReset = () => {
    setActiveStep(0);
    setData({
      docType: null,
      directory: null,
      allowNext: false,
      file: null,
      fileData: null,
      fileDetails: null,
      qmFolders: fileStructure,
    });
  };

  React.useEffect(() => {
    if (fileStructure) {
      setData((prevData) => ({
        ...prevData,
        qmFolders: fileStructure || [],
      }));
    }
  }, [fileStructure]);

  const handleNext = () => {
    // Handle last Step:
    if (activeStep === 2) {
      // docType and directory was read from file successfully allow user to continue
      handleAllowNext(true);
    } else {
      handleAllowNext(false);
    }
    if (activeStep === steps.length - 1) {
      handleReset();
      return;
    }

    // Normal step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 2 || activeStep === 4) {
      // docType and directory was read from file successfully allow user to continue
      handleAllowNext(true);
    } else {
      handleAllowNext(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAllowNext = (allow) => {
    setData((prevData) => ({ ...prevData, allowNext: allow }));
  };

  const handleDocumentTypeSelected = (docType) => {
    // Handle if doctype already read from fileName allow next and set doctype
    // Maybe do this already in filetoUpload
    setData((prevData) => ({ ...prevData, docType }));
    // handleNext();
    handleAllowNext(false);
  };

  const handleDirectorySelected = (directory) => {
    setData((prevData) => ({ ...prevData, directory }));
    handleNext();
    // handleAllowNext(true);
  };

  const handleFileToUpload = (file) => {
    let fileData = null;
    if (!file) {
      setData((prevData) => ({ ...prevData, file: null, allowNext: false }));
      return;
      // handleAllowNext(false);
    }
    if (file) {
      fileData = getFileDataFromName(file?.name);
    }

    setData((prevData) => ({
      ...prevData,
      file,
      fileData,

      docType: fileData?.fileType,
      allowNext: true,
    }));
  };

  const handleDocumentDetails = (fileDetails) => {
    setData((prevData) => ({
      ...prevData,
      fileDetails,
      allowNext: true,
    }));
    handleNext();
  };

  const handleUploadSuccess = () => {
    handleNext();
  };

  console.log("data :>> ", data);

  const stepsComponents = [
    <SelectFileStep key={1} handleFile={handleFileToUpload} />,
    <SelectDocumentDataStep
      key={2}
      data={data}
      onSubmitDocument={handleDocumentTypeSelected}
      onSubmitDirectory={handleDirectorySelected}
    />,
    <WorkFlowStep
      key={3}
      onSubmitDetails={handleDocumentDetails}
      data={data}
    />,
    <SummaryStep key={4} data={data} />,
    <UploadFileStep key={5} data={data} handleNext={handleUploadSuccess} />,
    <SuccessStep key={6} />,
  ];

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xl"
      fullWidth
      aria-describedby="import-dialog"
    >
      <DialogActions
        style={{
          borderBottomStyle: "solid",
          borderWidth: "2px",
          borderColor: "lightgrey",
          paddingTop: 0,
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ pl: 1 }} variant="h4">
          {steps[activeStep]}
        </Typography>
        <Button onClick={handleClose} color="inherit">
          <Icon width="40" icon="icon-park-solid:close-one" />
        </Button>
      </DialogActions>
      <DialogContent>{stepsComponents[activeStep]}</DialogContent>
      <ProgressMobileStepper
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        handleClose={() => handleClose("REFETCH")}
        activeStep={activeStep}
        steps={steps.length}
        lastStep={activeStep === steps.length - 1}
        lastStepButtonText={"NEUES DOKUMENT HOCHLADEN"}
        allowNext={data.allowNext}
      />
    </Dialog>
  );
}

FileUploadDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default FileUploadDialog;
