import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import DocumentDetails from "../../sections/@dashboard/files/FileData/DocumentDetails";
import { useUpdateFileDataMutation } from "../../features/fileData/fileDataApiSlice";

function EditFileDataDialog({ dialog, handleClose }) {
  const { type, data } = dialog;
  const { fileData } = data;
  const { handleSubmit, control, setValue, watch, setError } = useForm({
    defaultValues: {
      ...fileData,
      assignedUser: fileData?.assignedUser?._id ?? "",
      responsibleUser: fileData?.responsibleUser?._id ?? "",
      creator: fileData?.creator?._id ?? "",
    },
  });

  const [updateFileData, { isError }] = useUpdateFileDataMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (!data.fileName.includes(fileData.extension)) {
      let fileNameWithExt = `${data.fileName + fileData.extension}`;
      setValue("fileName", fileNameWithExt);
      data.fileName = fileNameWithExt;
    }
    const newData = {
      fileName: data?.fileName,
      status: data?.status,
      version: data?.version,
      type: data?.type,
      assignedUser: data?.assignedUser,
      responsibleUser: data?.responsibleUser,
      creator: data?.creator,
      auditRelevant: data?.auditRelevant,
      isCheckedOut: data?.isCheckedOut,
      allOffices: data?.allOffices,
      accessRestricted: data?.accessRestricted,
      officeRef: data?.officeRef,
      comment: data?.comment,
      recall: data?.recall,
    };

    if (data?.readingConfirmation && !fileData.readingConfirmation) {
      if (!data?.readingDueDate) {
        return alert("Bitte ein Datum für die Lesebestätigung angeben.");
      }

      if (data?.userList?.length === 0) {
        return alert(
          "Bitte Benutzer oder Gruppe auswählen die eine Lesebestätigung erhalten sollen."
        );
      }

      if (data?.status !== "released") {
        return alert(
          "Nur für freigegebene Dokumente können Lesebestätigungen angefordert werden."
        );
      }

      newData.readingConfirmation = true;
      newData.newReadingConfirmation = true;
      newData.readingDueDate = data.readingDueDate;
      newData.userList = data.userList;

      // return console.log("data :>> ", data);
    }

    if (data.recall) {
      newData.recallDate = data.recallDate;
    }

    // Either delete fields that should not be uploaded to prevent errors on updating fileData or only upload editable fields

    try {
      let result = await updateFileData({
        _id: fileData._id,
        updateFields: { ...newData },
      }).unwrap();
      if (result.type === "success") {
        // console.log("result :>> ", result);
        dispatch(
          setDialog({
            title: "Dokument geändert!",
            type: "SUCCESS",
            data: {
              successText: "Dokument wurde erfolgreich geändert!",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <Dialog open maxWidth="lg">
        <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
        <DialogContent>
          <form className="form">
            <DocumentDetails
              fileData={dialog.data.fileData}
              control={control}
              watch={watch}
            />
          </form>
        </DialogContent>
        {isError && (
          <div style={{ marginTop: 15 }}>
            <ErrorAlert errorMessage="Fehler beim Ändern des Dokuments" />
          </div>
        )}
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
          >
            {"SPEICHERN"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditFileDataDialog.propTypes = {
  dialog: PropTypes.object,
  handleClose: PropTypes.func,
};

export default EditFileDataDialog;
