import React from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getFileTypeLabelFromValue } from "../../../../utils/fileUtils";

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

const SummaryStep = ({ data }) => {
  return (
    <StyledPaper>
      <Typography variant="h6">
        Bitte prüfen Sie die eingegeben Daten.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <List>
            <ListItem>
              <ListItemText
                primary="Dokumententyp"
                secondary={getFileTypeLabelFromValue(data.docType)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Verzeichnis"
                secondary={data?.directory || "N/A"}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <List>
            <ListItem>
              <ListItemText
                primary="Dateiname"
                secondary={data.fileDetails.fileName}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Version"
                secondary={data.fileDetails.version}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Status"
                secondary={data.fileDetails.status}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Kommentar"
                secondary={data.fileDetails.comment}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <List>
            <ListItem>
              <ListItemText
                primary="Wiedervorlage"
                secondary={data.fileDetails.recall ? "Ja" : "Nein"}
              />
            </ListItem>
            {data.fileDetails.recall && (
              <React.Fragment>
                <ListItem>
                  <ListItemText
                    primary="Wiedervorlage in"
                    secondary={data.fileDetails.recallPeriod}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Erinnerungsdatum"
                    secondary={data.fileDetails.recallDate}
                  />
                </ListItem>
              </React.Fragment>
            )}
            <ListItem>
              <ListItemText
                primary="Freigegeben"
                secondary={data.fileDetails.released ? "Ja" : "Nein"}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default SummaryStep;
