import React from "react";
import { Chip, Grid } from "@mui/material";
import { Controller } from "react-hook-form";

// ControlledChipGroup ist die übergeordnete Komponente
const ControlledChipGroup = ({ control, name, label, itemArray, watch }) => {
  const selectedItems = watch(name) || []; // Holen der aktuellen Auswahl

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <h3>{label}</h3>
      </Grid>

      <Grid item xs={12} container spacing={1}>
        {itemArray.map((item) => (
          <Grid item key={item.value}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                const handleChipClick = () => {
                  const newSelection = selectedItems.includes(item.value)
                    ? selectedItems.filter((val) => val !== item.value)
                    : [...selectedItems, item.value];

                  // Update the selected values by setting them to the field
                  field.onChange(newSelection); // Dies ersetzt setValue
                };

                return (
                  <Chip
                    label={item.label}
                    clickable
                    color={
                      selectedItems.includes(item.value) ? "primary" : "default"
                    }
                    onClick={handleChipClick}
                  />
                );
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ControlledChipGroup;
