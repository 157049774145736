// NotificationsPage.js
import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  Divider,
  Typography,
  ListSubheader,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Scrollbar from "../components/scrollbar/Scrollbar";
import NotificationItem from "../layouts/dashboard/header/notification/NotificationItem";
import {
  useGetNotesQuery,
  useMarkAllAsReadMutation,
} from "../features/notes/notesApiSlice";
import { apiSlice } from "../app/api/apiSlice";
import Iconify from "../components/iconify";

export default function NotificationsPage() {
  const { data } = useGetNotesQuery();
  const [markAllAsRead] = useMarkAllAsReadMutation();
  const dispatch = useDispatch();

  const [notesRead, setNotesRead] = useState([]);
  const [notesNotRead, setNotesNotRead] = useState([]);
  const [taskToShow, setTaskToShow] = useState(5);

  useEffect(() => {
    if (data) {
      setNotesRead(data.notesRead);
      setNotesNotRead(data.notesNotRead);
    } else {
      setNotesRead([]);
      setNotesNotRead([]);
    }
  }, [data]);

  const totalUnRead = notesNotRead?.length || 0;

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead();
      dispatch(apiSlice.util.resetApiState());
    } catch (error) {
      console.error("Failed to mark all notes as read:", error);
    }
  };

  const showMore = () => {
    setTaskToShow(taskToShow + 8);
  };

  return (
    <Box sx={{ px: 3, py: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box>
          <Typography variant="h4">Benachrichtigungen</Typography>
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            Sie haben {totalUnRead} ungelesene Nachrichten
          </Typography>
        </Box>
        {totalUnRead > 0 && (
          <Tooltip title="Alle Nachrichten auf gelesen setzen">
            <IconButton color="primary" onClick={handleMarkAllAsRead}>
              <Iconify icon="eva:done-all-fill" />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Scrollbar>
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{ py: 1, px: 2.5, typography: "overline" }}
            >
              Neue Nachrichten
            </ListSubheader>
          }
        >
          {notesNotRead.length > 0 ? (
            notesNotRead.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                isUnRead={true}
              />
            ))
          ) : (
            <Typography variant="subtitle2" sx={{ p: 2, textAlign: "center" }}>
              Keine neuen Nachrichten vorhanden.
            </Typography>
          )}
        </List>

        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{ py: 1, px: 2.5, typography: "overline" }}
            >
              Alte Nachrichten
            </ListSubheader>
          }
        >
          {notesRead.length > 0 ? (
            notesRead.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                isUnRead={false}
              />
            ))
          ) : (
            <Typography variant="subtitle2" sx={{ p: 2, textAlign: "center" }}>
              Keine alten Nachrichten vorhanden.
            </Typography>
          )}
        </List>
      </Scrollbar>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Box onClick={showMore} sx={{ p: 1 }}>
        <Button disabled fullWidth disableRipple>
          Keine weitere Nachrichten vorhanden
        </Button>
      </Box>
    </Box>
  );
}
