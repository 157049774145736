import React from "react";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { getDateFromString } from "../../../../utils/dateUtils";
import FileNameTextField from "../../../../components/forms/Fields/FileNameTextField";
import FileDataStatusSelect from "../../../../components/forms/Fields/FileDataStatusSelect";
import FileDataVersionField from "../../../../components/forms/Fields/FileDataVersionField";
import FileDataTypeSelect from "../../../../components/forms/Fields/FileDataTypeSelect";
import FileDataAssignedUserSelect from "../../../../components/forms/Fields/FileDataAssignedUserSelect";
import FileDataResponsibleUserSelect from "../../../../components/forms/Fields/FileDataResponsibleUserSelect";
import FileDataCreatorSelect from "../../../../components/forms/Fields/FileDataCreatorSelect";
import FileDataAuditRelevantCheckbox from "../../../../components/forms/Fields/FileDataAuditRelevantCheckbox";
import FileDataAccessRestrictedCheckbox from "../../../../components/forms/Fields/FileDataAccessRestrictedCheckbox";
import FileDataOfficeSelect from "../../../../components/forms/Fields/FileDataOfficeSelect";
import FileDataCommentField from "../../../../components/forms/Fields/FileDataCommentField";
import FileDataCheckedOutCheckbox from "../../../../components/forms/Fields/FileDataCheckedOutCheckbox";
import FileDataAllOfficesCheckbox from "../../../../components/forms/Fields/FileDataAllOfficesCheckbox";
import DEDateField from "../../../../components/forms/DEDateField";
import FileDataRecallCheckbox from "../../../../components/forms/Fields/FileDataRecallCheckbox";
import FileDataReadingConfirmationCheckbox from "../../../../components/forms/Fields/FileDataReadingConfirmationCheckbox";
import { useGetOfficesQuery } from "../../../../features/offices/officesApiSlice";
import { useGetUsersCleanQuery } from "../../../../features/users/usersApiSlice";
import SelectUsersAreasOffices from "../../../../components/forms/Forms/SelectUsersAreasOffices";

function DocumentDetails({ fileData, control, watch }) {
  const { data: offices } = useGetOfficesQuery();
  const { data: users = [] } = useGetUsersCleanQuery();
  const recall = watch("recall");
  const readingConfirmation = watch("readingConfirmation");

  return (
    <Paper elevation={0}>
      <Typography variant="h5" gutterBottom>
        Dokumentdetails
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Grundlegende Informationen */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Praxis
          </Typography>
          <FileDataOfficeSelect control={control} />
        </Grid>
        <Grid item xs={12}>
          <FileDataAllOfficesCheckbox control={control} />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2 }} />
      <Typography variant="h6" gutterBottom>
        Dokument
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FileNameTextField control={control} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FileDataVersionField control={control} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FileDataStatusSelect control={control} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FileDataTypeSelect control={control} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FileDataAuditRelevantCheckbox control={control} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FileDataAccessRestrictedCheckbox control={control} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FileDataCheckedOutCheckbox control={control} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FileDataRecallCheckbox control={control} />
        </Grid>
        {recall && (
          <Grid item xs={12} md={4}>
            <DEDateField
              control={control}
              name="recallDate"
              label="Gültigkeitsprüfung"
              errorMsg="Bitte ein Datum auswählen."
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">
            Die Person, die als Verantwortliche/r eingetragen ist, erhält die
            Benachrichtigung für die Gültigkeitsprüfung.
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 2 }} />

      {/* Verantwortliche und Ersteller */}
      <Typography variant="h6" gutterBottom>
        Zuweisungen
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <FileDataCreatorSelect control={control} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FileDataResponsibleUserSelect control={control} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FileDataAssignedUserSelect control={control} />
        </Grid>
        <Grid item xs={12}>
          <FileDataCommentField control={control} />
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={2}>
        {/* Lesebestätigungsliste */}
        <Typography variant="h6" gutterBottom>
          Lesebestätigung
        </Typography>
        <Grid item xs={12}>
          <FileDataReadingConfirmationCheckbox control={control} />
        </Grid>
        {fileData?.readingConfirmation ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Leser</strong>
                </TableCell>
                <TableCell>
                  <strong>Bestätigt</strong>
                </TableCell>
                <TableCell>
                  <strong>Datum</strong>
                </TableCell>
                <TableCell>
                  <strong>Erinnerungen</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileData?.readingConfirmationList.map((entry) => (
                <TableRow key={entry._id}>
                  <TableCell>{entry.reader.fullName}</TableCell>
                  <TableCell>
                    {entry.readingConfirmed ? "Ja" : "Nein"}
                  </TableCell>
                  <TableCell>{getDateFromString(entry.readAt)}</TableCell>
                  <TableCell>{entry.remindingCounter}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Typography noWrap variant="body1">
              <strong>Frist:</strong>{" "}
              {getDateFromString(fileData?.readingDueDate)}
            </Typography>
          </Table>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="body" gutterBottom>
                Noch keine Lesebestätigung angefordert.
              </Typography>
            </Grid>
            {readingConfirmation && (
              <>
                <Grid item xs={12} md={8}>
                  <Typography variant="body" gutterBottom>
                    <b>
                      Wichtig! Lesebestätigungen können nur für freigegebene
                      Dokumente erstellt werden.
                    </b>
                  </Typography>
                  {offices && users && (
                    <SelectUsersAreasOffices
                      control={control}
                      offices={offices}
                      users={users}
                    />
                  )}
                </Grid>
                <Grid item sx={{ mt: { xs: 0, md: 15 } }} xs={12} md={4}>
                  <DEDateField
                    control={control}
                    required={true}
                    name="readingDueDate"
                    label="Frist Lesebestätigung"
                    errorMsg="Bitte ein Datum auswählen."
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      <Divider sx={{ marginY: 2 }} />

      {/* Workflow Historie */}
      <Typography variant="h6" gutterBottom>
        Workflow Historie
      </Typography>
      {fileData?.workflow && fileData?.workflow.length > 0 ? (
        <List subheader={<ListSubheader>Änderungen</ListSubheader>}>
          {fileData?.workflow?.map((step, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`${step.action} - ${step.description}`}
                secondary={`${getDateFromString(step.updatedAt)} - von ${
                  step.updatedBy
                }`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body" gutterBottom>
          Noch kein Workflow vorhanden
        </Typography>
      )}

      <Divider sx={{ marginY: 2 }} />

      {/* Weitere Details */}
      <Typography variant="h6" gutterBottom>
        Weitere Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body1">
            <strong>Erstellt am:</strong>{" "}
            {getDateFromString(fileData?.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">
            <strong>Zuletzt geändert:</strong>{" "}
            {getDateFromString(fileData?.updatedAt)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">
            <strong>Freigegeben am:</strong>{" "}
            {getDateFromString(fileData?.releasedAt)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">
            <strong>Gültigkeitsprüfung:</strong>{" "}
            {getDateFromString(fileData?.recallDate)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Dateipfad:</strong>
            <br />
            {fileData?.filePath}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DocumentDetails;
