import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Typography,
  IconButton,
  Tooltip,
  alpha,
  Toolbar,
  InputBase,
  Button,
  InputAdornment,
} from "@mui/material";
import { Icon } from "@iconify/react";
import FileDataTableFilterSidebar from "./FileDataTableFilterSidebar";
import { useParams } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function FileDataTableToolbar(props) {
  const {
    numSelected,
    onSearch,
    activeFilters,
    openFilter,
    handleOpenFilter,
    handleCloseFilter,
    handleEditSelected,
  } = props;

  let { searchId } = useParams();

  const [searchValue, setSearchValue] = React.useState(
    searchId === "show" ? "" : searchId
  );

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
    onSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearchValue("");
    onSearch(false);
  };

  const handleEditFileData = () => {
    handleEditSelected();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderRadius: "5px 5px 0px 0px",
        bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.2),
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} ausgewählt
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Dokumente
        </Typography>
      )}
      <Search>
        <SearchIconWrapper>
          <Icon icon="mdi:search" width="1.2em" height="1.2em" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Suchen..."
          inputProps={{ "aria-label": "search" }}
          value={searchValue}
          onChange={handleSearchValue} // Update search term on change
          endAdornment={
            <InputAdornment sx={{ mr: 2 }} position="end">
              <IconButton
                aria-label="reset search"
                onClick={clearSearch}
                edge="end"
              >
                <Icon icon="mdi:clear-circle-outline" />
              </IconButton>
            </InputAdornment>
          }
        />
      </Search>
      <FileDataTableFilterSidebar
        openFilter={openFilter}
        onOpenFilter={handleOpenFilter}
        onCloseFilter={handleCloseFilter}
        activeFilters={activeFilters}
      />
      {numSelected > 0 && (
        <Tooltip title="Bearbeiten">
          <Button
            onClick={handleEditFileData}
            variant="outlined"
            sx={{ ml: 2, minWidth: numSelected > 1 ? 250 : 150 }}
            startIcon={<Icon icon="material-symbols:contract-edit-outline" />}
          >
            {numSelected > 1 ? "MEHRERE BEARBEITEN" : "BEARBEITEN"}
          </Button>
        </Tooltip>
      )}
    </Toolbar>
  );
}

FileDataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  activeFilters: PropTypes.object,
  openFilter: PropTypes.bool,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
};

export default FileDataTableToolbar;
