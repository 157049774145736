import React, { useState } from "react";
import {
  Button,
  Container,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../components/iconify";
import LawAccordion from "../sections/@dashboard/laws/LawAccordion";
import { setDialog } from "../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import useAuth from "../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import LawSort from "../sections/@dashboard/laws/LawSort";
import { styled, alpha, useTheme } from "@mui/material/styles";

const StyledSearch = styled(OutlinedInput)(({ theme, width }) => ({
  width: width,
  padding: "0px 12px",
  height: 50,
  transition: theme?.transitions.create(["box-shadow", "width"], {
    easing: theme?.transitions.easing.easeInOut,
    duration: theme?.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme?.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `2px !important`,
    borderColor: `${alpha(theme?.palette.grey[500], 0.32)} !important`,
  },
}));

export default function LawsPage() {
  const dispatch = useDispatch();
  const { status } = useAuth();
  const canEdit = status;
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [filterInput, setFilterInput] = useState("");
  const [sortOption, setSortOption] = useState("title-asc");

  const handleCreateNewLaw = () => {
    dispatch(setDialog({ content: "LawDialog", data: "", lawFlag: "NEW" }));
  };

  const handleLawClick = (lawId) => {
    navigate(`/dashboard/laws/${lawId}`);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  return (
    <>
      <Helmet>
        <title> Digit QM | Gesetzestexte </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          flexDirection={isMobile ? "column" : "row"}
        >
          <Typography variant="h4" gutterBottom>
            Gesetzesübersicht
          </Typography>
          {canEdit && (
            <Button
              variant="contained"
              sx={{ textTransform: "none", mt: isMobile ? 2 : 0 }}
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleCreateNewLaw}
            >
              Neues Gesetz einfügen
            </Button>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          flexDirection={isMobile ? "column" : "row"}
        >
          <StyledSearch
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            placeholder="Suche..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
          <LawSort handleSortChange={handleSortChange} />{" "}
        </Stack>

        <LawAccordion
          onLawClick={handleLawClick}
          selectedLawId={id}
          canEdit={canEdit}
          filterInput={filterInput}
          sortOption={sortOption}
        />
      </Container>
    </>
  );
}
