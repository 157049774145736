import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DocumentEditor from "../../sections/@dashboard/files/DocumentEditor/DocumentEditor";
import SpreadsheetEditor from "../../sections/@dashboard/files/DocumentEditor/SpreadsheetEditor";
import PdfViewer from "../../sections/@dashboard/files/DocumentEditor/PdfViewer";
import {
  downloadFileByID,
  uploadFileAxios,
} from "../../app/api/axios/services/files.service";
import { useReleaseDocumentMutation } from "../../features/fileData/fileDataApiSlice";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ReleaseDocumentForm from "../forms/Forms/ReleaseDocumentForm";
import { RELEASE_DOCUMENT } from "../forms/Forms/defaultValues";
import ReleaseDocView from "./ReleaseDocView";

export default function ReleaseDocWithPreviewDialog({ onClose, dialog }) {
  const hasFetched = React.useRef(false);
  const [file, setFile] = React.useState(null);

  const documentEditorRef = React.useRef(null);
  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (hasFetched.current) return;
    async function fetchData() {
      try {
        let f = await downloadFileByID(dialog.data.fileData._id); // Download file with id
        setFile(f);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
    // Only fetch data if dialog.data.fileData._id exists
    if (dialog.data.fileData._id) {
      fetchData();
    }
    hasFetched.current = true; // Set the flag to true after the effect runs
  }, [dialog.data.fileData._id]); // Dependency array with the file ID

  return (
    <Dialog maxWidth="xl" open onClose={handleClose}>
      <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
      <DialogContent>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <div style={{ minWidth: 600, minHeight: "70vh" }}>
            {dialog.data.fileData.fileName.includes(".doc") && file && (
              <DocumentEditor
                documentEditorRef={documentEditorRef}
                data={dialog?.data}
                file={file}
                preview={true}
              />
            )}
            {dialog.data.fileData.fileName.includes(".xls") && (
              <SpreadsheetEditor data={dialog?.data} />
            )}
            {dialog.data.fileData.fileName.includes(".pdf") && (
              <PdfViewer data={dialog?.data} />
            )}
          </div>
          <div style={{ maxWidth: 715 }}>
            {dialog?.data?.fileData && (
              <ReleaseDocView
                fileData={dialog.data.fileData}
                documentEditorRef={documentEditorRef}
              />
            )}
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Beenden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
