import React from "react";
import { Container } from "@mui/material";

import { Helmet } from "react-helmet-async";
import DisplaySurveys from "../sections/@dashboard/survey/DisplaySurveys";
import SurveyEvaluation from "../sections/@dashboard/survey/SurveyEvaluation";
import { Route, Routes } from "react-router-dom";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const SurveyPage = () => {
  return (
    <>
      <Helmet>
        <title> Digit QM | Befragungen </title>
      </Helmet>

      <Container maxWidth={""}>
        <Routes>
          <Route index element={<DisplaySurveys />} />
          <Route path="evaluate/:surveyId" element={<SurveyEvaluation />} />
        </Routes>
      </Container>
    </>
  );
};

export default SurveyPage;
