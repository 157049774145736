import React, { useState, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Popover,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import AuditItemStatus from "./AuditItemStatus";
import { useParams } from "react-router-dom";
import { useGetAuditByYearQuery } from "../../../features/audit/auditApiSlice";
import Loading from "../../../components/spinners/Loading";
import AuditItemResponsibleUser from "./AuditItemResponsibleUser";
import NoAuditError from "./NoAuditError";
import Iconify from "../../../components/iconify";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

const MemoizedAuditItemResponsibleUser = memo(AuditItemResponsibleUser);

// Die AuditTable-Komponente
const AuditTable = () => {
  let { year } = useParams();
  const [open, setOpen] = useState(null);
  const [auditItem, setAuditItem] = useState(null);
  const dispatch = useDispatch();

  const {
    data: audit,
    error,
    isError,
    isLoading,
    isFetching,
  } = useGetAuditByYearQuery(year);

  const handleAddAuditItem = () => {
    dispatch(
      setDialog({
        title: "Auditpunkt hinzufügen",
        type: "CREATE",
        content: "auditItem",
        data: {
          auditItem: {
            period: "",
            task: "",
            responsibleUser: "",
            status: "open",
          },
          auditId: audit._id,
        },
      })
    );
  };

  const handleOpenMenu = (event, auditItem) => {
    setAuditItem(auditItem);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAuditItem(null);
    setOpen(null);
  };

  const handleEditAuditItem = () => {
    dispatch(
      setDialog({
        title: "Auditpunkt bearbeiten",
        type: "EDIT",
        content: "auditItem",
        data: { auditItem },
      })
    );
    handleCloseMenu();
  };

  const handleDeleteAuditItem = () => {
    dispatch(
      setDialog({
        title: "Auditpunkt löschen",
        type: "DELETE",
        content: "auditItem-delete",
        data: { auditItem },
      })
    );
    handleCloseMenu();
  };

  if (error && error?.data?.noAudits) {
    return <NoAuditError />;
  }

  if (isError) {
    return (
      <Typography>
        Probleme beim Laden der Audit Checkliste. Bitte versuchen Sie es erneut.
      </Typography>
    );
  }

  return (
    <div>
      <Box sx={{ m: 2 }}>
        <Button variant="contained" onClick={handleAddAuditItem}>
          Auditpunkt hinzufügen
        </Button>
      </Box>
      <TableContainer
        sx={{ maxHeight: "80vh" }}
        elevation={3}
        component={Paper}
      >
        {(isLoading || isFetching) && <Loading />}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Nr.</TableCell>
              <TableCell>Zeitraum/-punkt</TableCell>
              <TableCell>Aufgaben/Termine</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                Kapitel im Mojave QM
              </TableCell>
              <TableCell>Verantwortlich</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audit &&
              audit?.auditItems.map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell>{row.period}</TableCell>
                  <TableCell>{row.task}</TableCell>
                  <TableCell>{row.chapter}</TableCell>
                  <TableCell>
                    <MemoizedAuditItemResponsibleUser
                      auditItemId={row._id}
                      responsibleUser={row.responsibleUser}
                      loading={isLoading || isFetching}
                    />
                  </TableCell>
                  <TableCell>
                    <AuditItemStatus
                      auditItemId={row._id}
                      status={row.status}
                      loading={isLoading || isFetching}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={(e) => handleOpenMenu(e, row)}
                    >
                      <Iconify icon={"eva:more-vertical-fill"} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 180,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => handleEditAuditItem()}>
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Bearbeiten
          </MenuItem>
          <MenuItem
            onClick={() => handleDeleteAuditItem()}
            sx={{ color: "error.main" }}
          >
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Löschen
          </MenuItem>
        </Popover>
      </TableContainer>
    </div>
  );
};

export default AuditTable;
