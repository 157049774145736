import React from "react";
import AuditTable from "./AuditTable";

export default function SingleAuditView() {
  return (
    <div>
      <AuditTable />
    </div>
  );
}
