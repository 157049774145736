import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Icon } from "@iconify/react";
import { QRCodeCanvas } from "qrcode.react";

export default function QRDialog({ dialog, handleClose }) {
  const qrCodeRef = React.useRef();
  const [qrSize, setQrSize] = React.useState(256); // Default size: Mittel (256)
  const { value } = dialog;

  const handleSizeChange = (event) => {
    setQrSize(event.target.value);
  };

  const handlePrint = () => {
    // Get the canvas element
    const canvas = qrCodeRef.current.querySelector("canvas");

    if (!canvas) {
      alert("Canvas not found.");
      return;
    }

    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL("image/png");

    // Open a new window and add the QR code as an image
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>QR Code drucken</title>
          <style>
            body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }
            img { max-width: 100%; height: auto; }
          </style>
        </head>
        <body>
          <img src="${dataURL}" alt="QR Code" />
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const handleCopy = async () => {
    try {
      // Get the canvas element
      const canvas = qrCodeRef.current.querySelector("canvas");

      if (!canvas) {
        alert("Canvas not found.");
        return;
      }

      // Convert the canvas to a blob
      canvas.toBlob(async (blob) => {
        if (!blob) {
          alert("Failed to create QR code image.");
          return;
        }

        // Copy the image blob to the clipboard
        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              "image/png": blob,
            }),
          ]);
          alert("QR Code copied to clipboard!");
        } catch (error) {
          console.error("Failed to copy QR Code: ", error);
          alert("Failed to copy QR Code.");
        }
      });
    } catch (error) {
      console.error("Error during copying: ", error);
      alert("An unexpected error occurred.");
    }
  };

  return (
    <Dialog
      open
      keepMounted
      onClose={handleClose} // Close when click oustside the dialog
      aria-describedby="dialog-success"
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dialog?.title ?? "Dialog"}
          <Button onClick={() => handleClose("REFETCH")} color="inherit">
            <Icon width="40" icon="icon-park-solid:close-one" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>QR Code Größe</InputLabel>
          <Select
            label="QR Code Größe"
            value={qrSize}
            onChange={handleSizeChange}
          >
            <MenuItem value={128}>Klein (128)</MenuItem>
            <MenuItem value={256}>Mittel (256)</MenuItem>
            <MenuItem value={512}>Groß (512)</MenuItem>
          </Select>
        </FormControl>
        <div ref={qrCodeRef} style={{ textAlign: "center", marginTop: "1rem" }}>
          <QRCodeCanvas value={value} size={qrSize} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => handleClose("REFETCH")}>
          Beenden
        </Button>
        <Button onClick={handlePrint} color="primary" variant="contained">
          QR Code drucken
        </Button>
        <Button onClick={handleCopy} color="secondary" variant="contained">
          QR Code kopieren
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// import * as React from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { Icon } from "@iconify/react";
// import { QRCodeSVG } from "qrcode.react";

// export default function QRDialog({ dialog, handleClose }) {
//   const qrCodeRef = React.useRef();
//   const [qrSize, setQrSize] = React.useState(256); // Default size: Mittel (256)
//   const { value } = dialog;

//   const handleSizeChange = (event) => {
//     setQrSize(event.target.value);
//   };

//   const handlePrint = () => {
//     const printWindow = window.open("", "_blank");
//     const qrContent = qrCodeRef.current.outerHTML;

//     // Create a simple HTML document with only the QR code
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>QR Code drucken</title>
//           <style>
//             body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }
//           </style>
//         </head>
//         <body>${qrContent}</body>
//       </html>
//     `);
//     printWindow.document.close();
//     printWindow.focus();
//     printWindow.print();
//     printWindow.close();
//   };
//   return (
//     <Dialog
//       open
//       keepMounted
//       onClose={handleClose} // Close when click oustside the dialog
//       aria-describedby="dialog-success"
//     >
//       <DialogTitle>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           {dialog?.title ?? "Dialog"}
//           <Button onClick={() => handleClose("REFETCH")} color="inherit">
//             <Icon width="40" icon="icon-park-solid:close-one" />
//           </Button>
//         </div>
//       </DialogTitle>
//       <DialogContent>
//         <FormControl fullWidth margin="normal">
//           <InputLabel>QR Code Größe</InputLabel>
//           <Select
//             label="QR Code Größe"
//             value={qrSize}
//             onChange={handleSizeChange}
//           >
//             <MenuItem value={128}>Klein (128)</MenuItem>
//             <MenuItem value={256}>Mittel (256)</MenuItem>
//             <MenuItem value={512}>Groß (512)</MenuItem>
//           </Select>
//         </FormControl>
//         <div ref={qrCodeRef} style={{ textAlign: "center", marginTop: "1rem" }}>
//           <QRCodeSVG value={value} size={qrSize} />
//         </div>
//       </DialogContent>
//       <DialogActions>
//         <Button color="error" onClick={() => handleClose("REFETCH")}>
//           Beenden
//         </Button>
//         <Button onClick={handlePrint} color="primary" variant="contained">
//           QR Code drucken
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }
