import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

export default function LoginTextField({ control, name = "username" }) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: "Eingabe ist erforderlich.",
    },
  });

  return (
    <TextField
      label="Benutzername"
      variant="outlined"
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      error={!!error}
      helperText={error?.message}
    />
  );
}

LoginTextField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
};
