import React, { useState, useEffect } from "react";
import {
  Paper,
  Container,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import Iconify from "src/components/iconify";
import FbDataForm from "./EntryDataFiles/FbDataForm";
import FbDataFormRisk from "./Forms/FbDataFormRisk";
import FbDataControls from "./EntryDataFiles/FbDataControls";
import { useUpdateFBMutation } from "../../../features/entry/entryApiSlice";
import { useGetEntryByIDQuery } from "../../../features/entry/entryApiSlice"; // Importiere den Hook für die Entry-ID-Abfrage
import MySlideSnackbar from "../../../components/snackbar/MySlideSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { apiSlice } from "../../../app/api/apiSlice";
import Loading from "../../../components/spinners/Loading";

const EntryDetailLayout = () => {
  const { id } = useParams(); // Verwende useParams, um die ID aus der URL zu holen
  const { data: entryData, isLoading, error } = useGetEntryByIDQuery(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateFB] = useUpdateFBMutation();

  const { control, handleSubmit, reset, watch, formState } = useForm({
    defaultValues: entryData || {
      status: "",
      frequency: "",
      classification: "",
      scope: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "measure",
  });
  const { fields: kontrolleFields } = useFieldArray({
    control,
    name: "kontrolle",
  });
  const meetingContent = watch("discussionInTeamMeetingContent");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (entryData) {
      reset(entryData);
    }
  }, [entryData, reset]);

  const handleBackToList = () => {
    navigate("/dashboard/entry"); // Zurück zur Liste der Einträge
  };

  const handleUpdate = async (data) => {
    const result = await updateFB({ ...data });
    if (result) {
      setMsg("Sie haben den Eintrag erfolgreich aktualisiert!");
      setSnackbarOpen(true);
      dispatch(apiSlice.util.resetApiState());
    }
  };

  const handleKontrolle = () => {
    dispatch(
      setDialog({
        content: "KontrolleDialog",
        data: entryData,
      })
    );
  };

  const handleRiskManagement = () => {
    dispatch(
      setDialog({
        content: "RiskDialog",
        data: entryData,
      })
    );
  };

  const handleRemove = (index) => {
    remove(index);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Horizontale Zentrierung
          alignItems: "center", // Vertikale Zentrierung
          height: "100vh", // Nimmt die gesamte Höhe des Viewports ein
        }}
      >
        <Loading />
      </div>
    );
  }

  if (error) {
    return <Typography>Error loading entry data!</Typography>;
  }
  if (!entryData) {
    return (
      <Typography variant="h6" color="text.secondary" align="center">
        Keine Daten gefunden oder Eintrag existiert nicht.
      </Typography>
    );
  }

  return (
    <>
      <Paper
        style={{
          width: "100%",
          height: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
          borderColor: "#CCCCCC",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <IconButton onClick={handleBackToList}>
              <Iconify icon="eva:arrow-back-fill" /> Back
            </IconButton>
          </Box>
        )}
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form onSubmit={handleSubmit(handleUpdate)}>
            {entryData.incidentType === "risiko" ? (
              <FbDataFormRisk
                selectedItem={entryData}
                control={control}
                append={append}
                fields={fields}
                kontrolleFields={kontrolleFields}
                meetingContent={meetingContent}
                handleRemove={handleRemove}
              />
            ) : (
              <FbDataForm
                selectedItem={entryData}
                control={control}
                append={append}
                fields={fields}
                kontrolleFields={kontrolleFields}
                meetingContent={meetingContent}
                handleRiskManagement={handleRiskManagement}
                handleRemove={handleRemove}
              />
            )}

            <FbDataControls
              formState={formState}
              handleSubmit={handleSubmit}
              handleKontrolle={handleKontrolle}
              handleRiskManagement={handleRiskManagement}
              selectedItem={entryData}
              isMobile={isMobile}
            />
          </form>
        </Container>
      </Paper>

      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </>
  );
};

export default EntryDetailLayout;
