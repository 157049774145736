import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  Card,
  CardContent,
  Stack,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import { useGetAllLawsQuery } from "../../../features/laws/lawsApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/spinners/Loading";
import Label from "../../../components/label";
import { useTheme } from "@emotion/react";
import { serverURL } from "../../../app/api/axios/http.service";

export default function LawAccordion({
  onLawClick,
  selectedLawId,
  canEdit,
  filterInput,
  sortOption,
}) {
  const theme = useTheme();
  const { data: laws, isLoading } = useGetAllLawsQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(selectedLawId || false);

  useEffect(() => {
    if (selectedLawId) setExpanded(selectedLawId);
  }, [selectedLawId]);

  const handleChange = (lawId) => (event, isExpanded) => {
    setExpanded(isExpanded ? lawId : false);
    navigate(isExpanded ? `/dashboard/laws/${lawId}` : "/dashboard/laws");
  };

  const handleDelete = (lawId) => {
    dispatch(
      setDialog({ content: "DeleteForm", data: { lawId }, art: "DELETELAW" })
    );
  };

  const handleEdit = (law) => {
    dispatch(setDialog({ content: "LawDialog", data: law, lawFlag: "EDIT" }));
  };

  // Filter- und Sortierlogik
  const filteredLaws = Array.isArray(laws)
    ? laws.filter((law) =>
        `${law.title} ${law.description}`
          .toLowerCase()
          .includes(filterInput.toLowerCase())
      )
    : [];

  const sortedLaws = filteredLaws.sort((a, b) => {
    const [field, order] = sortOption.split("-");

    if (field === "title") {
      return order === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    }

    if (field === "date") {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (field === "favorite") {
      if (order === "asc") {
        return b.favorite - a.favorite;
      } else {
        return a.favorite - b.favorite;
      }
    }

    return 0; // Default: Keine Sortierung
  });

  if (isLoading) return <Loading />;

  return (
    <Card sx={{ width: "100%", mt: 4 }}>
      <CardContent sx={{ p: 0 }}>
        {sortedLaws.length > 0 ? (
          sortedLaws.map((law) => {
            // Berechtigungslogik
            const canEditOrDelete =
              (law?.isFromDigitMed && canEdit === "SuperAdmin") || // Nur SuperAdmin darf bearbeiten, wenn isFromDigitMed true
              (!law.isFromDigitMed &&
                (canEdit === "Admin" || canEdit === "SuperAdmin")); // Admin oder SuperAdmin dürfen bearbeiten, wenn isFromDigitMed false

            return (
              <Accordion
                key={law._id} // Verwende _id als Schlüssel für das Accordion
                expanded={expanded === law._id} // Setze das Accordion auf "expanded", wenn _id übereinstimmt
                onChange={handleChange(law._id)} // Übergibt die _id zum Ändern des Zustands
                sx={{
                  width: "100%",
                  backgroundColor: "transparent",
                  m: 0,
                }}
              >
                <AccordionSummary
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container sx={{ flexGrow: 1, alignItems: "center" }}>
                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h6">{law.title}</Typography>
                      {law.favorite && (
                        <Iconify
                          icon="ic:round-star"
                          sx={{ ml: 1, color: "gold" }}
                        />
                      )}
                    </Grid>
                    {law.subjectToDisplay && (
                      <Label
                        color="error"
                        sx={{
                          ml: {
                            xs: 0,
                            sm: 2,
                          },
                          mt: {
                            xs: 1,
                            sm: 0,
                          },
                        }}
                      >
                        Aushangpflichtig
                      </Label>
                    )}
                  </Grid>

                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {law.isFromDigitMed && (
                      <Tooltip title="Vom DigitMed erstellt">
                        <Iconify
                          icon="ic:baseline-fact-check" // Beispiel-Icon
                          sx={{
                            color: theme.palette.secondary.main,
                            mr: 1,
                            fontSize: "24px",
                          }}
                        />
                      </Tooltip>
                    )}
                    <Iconify
                      icon="ic:round-expand-more"
                      sx={{
                        transition: "transform 0.2s ease-in-out",
                        transform:
                          expanded === law._id
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                      }}
                    />
                  </Grid>
                </AccordionSummary>

                <AccordionDetails sx={{ backgroundColor: "white" }}>
                  <Typography>{law.description}</Typography>
                  <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    {law?.documentPath && (
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ mt: "10px" }}
                      >
                        <Tooltip title="Dokument öffnen">
                          <Iconify
                            icon="ic:baseline-description"
                            sx={{ cursor: "pointer" }}
                          />
                          <Link
                            href={`${serverURL()}/public/law/document/${
                              law._id
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ cursor: "pointer", ml: 0.5 }}
                          >
                            Dokument öffnen
                          </Link>
                        </Tooltip>
                      </Stack>
                    )}
                    {law.externalUrl && (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Tooltip title={law.externalUrl}>
                          <Iconify
                            icon="ic:baseline-public"
                            sx={{ cursor: "pointer" }}
                          />
                          <Link
                            href={`https://${law.externalUrl}`}
                            target="_blank"
                            rel="noopener"
                            sx={{ cursor: "pointer", ml: 0.5 }}
                          >
                            Website öffnen
                          </Link>
                        </Tooltip>
                      </Stack>
                    )}
                  </Stack>

                  {canEditOrDelete && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ textTransform: "none" }}
                        onClick={() => handleEdit(law)}
                      >
                        Bearbeiten
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ textTransform: "none" }}
                        onClick={() => handleDelete(law._id)}
                      >
                        Löschen
                      </Button>
                    </Stack>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
            Keine Gesetze gefunden!
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
