import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { useUpdateTenantMutation } from "../../../features/tenants/tenantsApiSlice";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

export default function ModuleAddDialog({ tenant, module }) {
  const [open, setOpen] = React.useState(false);
  const [updateTenant] = useUpdateTenantMutation();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBookModule = async () => {
    console.log("tenant :>> ", tenant);
    console.log("module :>> ", module);
    let updateData;
    if (module === "USER") {
      updateData = { allowedUsers: tenant.allowedUsers + 10 };
    }
    if (module === "OFFICE") {
      updateData = { allowedOffices: tenant.allowedOffices + 1 };
    }
    try {
      let result = await updateTenant(updateData).unwrap();
      console.log("result :>> ", result);
      if (result?.type === "success") {
        dispatch(
          setDialog({
            title: "Erfolgreich geändert.",
            type: "SUCCESS",
            data: {
              successText: result?.message ?? "",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      dispatch(
        setDialog({
          title: "Fehler",
          type: "ERROR",
          data: {
            errorText: "Fehler beim buchen, bitte versuchen Sie es erneut.",
          },
        })
      );
    } finally {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        // disabled={loading}
        fullWidth
        sx={{ m: 2 }}
        onClick={handleClickOpen}
      >
        Weitere {module === "OFFICE" ? "Praxis" : "Benutzer"} Buchen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${module === "OFFICE" ? "Praxis" : "Benutzer"} buchen`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Hier können Sie kostenpflichtig weitere{" "}
            {module === "OFFICE" ? "Praxen" : "Benutzer"} buchen.
          </Typography>
          <br />
          <Typography variant="h5">
            Kosten:{" "}
            {module === "OFFICE"
              ? "25 Euro/Monat für eine Praxis"
              : "25 Euro/Monat für 10 Benutzer"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={loading}
            onClick={handleBookModule}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {"Kostenpflichtig buchen"}
          </Button>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
