import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Loading from "../spinners/Loading";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import { useDeleteAuditItemMutation } from "../../features/audit/auditApiSlice";

function AuditItemDeleteDialog({ auditItem, handleClose }) {
  const dispatch = useDispatch();

  const [deleteAuditItem, { isLoading, isError }] =
    useDeleteAuditItemMutation();

  const handleDeleteAuditItem = async () => {
    try {
      let result = await deleteAuditItem({
        auditItemId: auditItem._id,
        auditId: auditItem.auditRef,
      }).unwrap();
      if (result?.status === "success") {
        dispatch(
          setDialog({
            title: auditItem.task + " wurde erfolgreich gelöscht!",
            type: "SUCCESS",
            data: {
              successText: auditItem.task + " wurde erfolgreich gelöscht!",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <Dialog
        onClose={!isLoading ? () => handleClose() : null}
        open
        maxWidth="lg"
      >
        <DialogTitle>Auditpunkt löschen</DialogTitle>
        <DialogContent>
          {isLoading && (
            <div style={{ minHeight: 300, minWidth: 300 }}>
              <Loading />
            </div>
          )}

          {!isLoading && (
            <div>
              <Typography variant="h3" id="success-dialog-description">
                Möchten Sie diesen Auditpunkt wirklich endültig löschen?
              </Typography>
              <Typography variant="h5">Auditpunkt:</Typography>
              <Typography variant="h5">{auditItem.task}</Typography>
            </div>
          )}
          {isError && (
            <ErrorAlert errorMessage="Fehler beim Löschen des Auditpunkts" />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose} color="primary">
            Beenden
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleDeleteAuditItem}
            color="error"
            variant="contained"
            autoFocus
          >
            LÖSCHEN
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AuditItemDeleteDialog;

AuditItemDeleteDialog.propTypes = {
  auditItem: PropTypes.object,
  dialogType: PropTypes.string,
};
