import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

function FileDataRecallCheckbox({ control }) {
  return (
    <Controller
      name="recall"
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value} />}
          label="Gültigkeitsprüfung"
        />
      )}
    />
  );
}

FileDataRecallCheckbox.propTypes = {
  control: PropTypes.object,
};

export default FileDataRecallCheckbox;
