import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

function SimpleSelectField({ control, name, label, options }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} error={error && true}>
            {options &&
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText error>{error && "Bitte auswählen"}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

SimpleSelectField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
};

export default SimpleSelectField;
