import { apiSlice } from "../../app/api/apiSlice";

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: () => ({
        url: "/events",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Event", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Event", id })),
          ];
        }
        return [{ type: "Event", id: "LIST" }];
      },
    }),

    // Historie eines Events (z.B. alle Instanzen eines Serientermins)
    getEventHistoryById: builder.query({
      query: (eventId) => ({
        url: `/events/${eventId}/history`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event", id: arg }],
    }),

    // Einzelnes Event abfragen (einschließlich eines Serientermins)
    getEventById: builder.query({
      query: (id) => `/events/${id}`,
      providesTags: (result, error, arg) => [{ type: "Event", id: result?.id }],
    }),

    // Neues Event erstellen
    addNewEvent: builder.mutation({
      query: (event) => ({
        url: "/events",
        method: "POST",
        body: { ...event },
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }],
    }),

    // Ein Event aktualisieren (einschließlich eines Serientermins)
    updateEvent: builder.mutation({
      query: (eventUpdate) => ({
        url: "/events",
        method: "PATCH",
        body: { ...eventUpdate },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),

    // Serientermine aktualisieren (falls die Serie aktualisiert werden muss)
    updateSeries: builder.mutation({
      query: ({ seriesId, updatedData }) => ({
        url: `/events/series/${seriesId}`,
        method: "PATCH",
        body: updatedData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Event", id: arg.seriesId },
      ],
    }),

    // Eventstatus ändern
    updateEventStatus: builder.mutation({
      query: (status) => ({
        url: `/events/status`,
        method: "PATCH",
        body: { ...status },
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }],
    }),

    // Event löschen (inklusive Serienterminen)
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/events`,
        method: "DELETE",
        body: { ...id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),

    // Einzelnen Serientermin löschen
    deleteSeriesEvent: builder.mutation({
      query: (eventId) => ({
        url: `/events/${eventId}`,
        method: "DELETE",
        body: { eventId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Event", id: arg.eventId },
      ],
    }),

    // Alle Instanzen einer Serie löschen
    deleteSeries: builder.mutation({
      query: (seriesId) => ({
        url: `/events/series/${seriesId}`,
        method: "DELETE",
        body: { seriesId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Event", id: arg.seriesId },
      ],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventHistoryByIdQuery,
  useGetEventByIdQuery,
  useAddNewEventMutation,
  useUpdateEventMutation,
  useUpdateSeriesMutation,
  useUpdateEventStatusMutation,
  useDeleteEventMutation,
  useDeleteSeriesEventMutation,
  useDeleteSeriesMutation,
} = eventsApiSlice;
