import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Button, MenuItem, Typography } from "@mui/material";
import Iconify from "../../../components/iconify";

const SORT_BY_OPTIONS = [
  { value: "title-asc", label: "Titel (A-Z)" },
  { value: "title-desc", label: "Titel (Z-A)" },
  { value: "date-asc", label: "Erstellungsdatum (aufsteigend)" },
  { value: "date-desc", label: "Erstellungsdatum (absteigend)" },
  { value: "favorite-asc", label: "Favoriten (zuerst)" },
  { value: "favorite-desc", label: "Favoriten (zuletzt)" },
];

LawSort.propTypes = {
  handleSortChange: PropTypes.func.isRequired,
};

export default function LawSort({ handleSortChange }) {
  const [open, setOpen] = useState(null);
  const [selectedOption, setSelectedOption] = useState("title-asc");

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleSortChange(option); // Die Funktion wird aufgerufen, wenn eine Option ausgewählt wird.
    handleClose();
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        Sortierung:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {
            SORT_BY_OPTIONS.find((option) => option.value === selectedOption)
              .label
          }
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedOption}
            onClick={() => handleOptionSelect(option.value)}
            sx={{ typography: "body2" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
