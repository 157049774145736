import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Iconify from "../iconify";
import DocumentEditor from "../../sections/@dashboard/files/DocumentEditor/DocumentEditor";
import SpreadsheetEditor from "../../sections/@dashboard/files/DocumentEditor/SpreadsheetEditor";
import PdfViewer from "../../sections/@dashboard/files/DocumentEditor/PdfViewer";
import UpdateFileUserDialog from "./UpdateFileDialog/UpdateFileUserDialog";
import {
  checkInFileByID,
  checkOutFileByID,
  downloadFileByID,
  uploadFileAxios,
} from "../../app/api/axios/services/files.service";
import {
  useAssignFileDataMutation,
  useRevisionFileDataMutation,
  useUpdateFileDataMutation,
} from "../../features/fileData/fileDataApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import useAuth from "../../hooks/useAuth";
import UpdateFileAdminDialog from "./UpdateFileDialog/UpdateFileAdminDialog";
import ReleaseFileAdminDialog from "./UpdateFileDialog/ReleaseFileAdminDialog";
import { selectCurrentUserData } from "../../features/auth/authSlice";
import { handleShowReleaseButton } from "../../utils/fileUtils";

var SERVER_URL = "/api";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  SERVER_URL = "http://localhost:5001/api";
} else {
  // production code
  SERVER_URL = "/api";
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenFileDialog({ onClose, dialog }) {
  const { isAdmin, isEditor } = useAuth();
  const userData = useSelector(selectCurrentUserData);
  const dispatch = useDispatch();
  const hasFetched = React.useRef(false);
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [read, setRead] = React.useState(false);

  const [updateFileData] = useUpdateFileDataMutation();
  const [revisionFileData] = useRevisionFileDataMutation();
  const [assignFileData] = useAssignFileDataMutation();

  const documentEditorRef = React.useRef(null);

  // Function to check if the user has scrolled to the bottom
  const isBottom = (el) => {
    return el.scrollHeight - el.scrollTop <= el.clientHeight + 1; // Allow for rounding errors
  };

  function handleScroll(event) {
    if (isBottom(event.target)) {
      // Mark that the bottom of the document was reached.
      setRead(true);
    }
  }

  async function checkinFile() {
    try {
      let d = await checkInFileByID(dialog.data.fileData._id);
      if (d) {
        onClose();
      }
    } catch (error) {
      console.error(error);
      window.alert(
        "Es gab ein Problem beim Einchecken der Datei.\nBitte versuchen Sie es erneut."
      );
    }
  }

  const handleClose = async () => {
    // action for confirmation before close: EDIT REVIEW
    if (["EDIT", "REVIEW"].includes(dialog?.data?.action)) {
      // User should be asked if he wants to save changes
      if (window.confirm("Haben Sie Ihre Änderungen gespeichert?")) {
        checkinFile();
      } else {
        // if not return to document
        return;
      }
    } else {
      // Doc does not need to be saved:
      checkinFile();

      onClose();
    }
  };

  const handleAssignFile = async (docFormData, formData) => {
    return await Promise.all([
      assignFileData({
        _id: dialog.data.fileData._id,
        ...dialog.data.fileData,
        ...formData,
      }).unwrap(),
      uploadFileAxios(docFormData, dialog.data.fileData._id),
    ]);
  };

  const handleRevisionFile = async (docFormData, formData) => {
    return await Promise.all([
      revisionFileData({
        _id: dialog.data.fileData._id,
        fileName: dialog.data.fileData.fileName,
        ...formData,
      }).unwrap(),
      uploadFileAxios(docFormData, dialog.data.fileData._id),
    ]);
  };

  const handleSaveFile = async (docFormData) => {
    return await Promise.all([
      updateFileData({
        _id: dialog.data.fileData._id,
        newWorkflow: {
          action: "save",
        },
      }).unwrap(),
      uploadFileAxios(docFormData, dialog.data.fileData._id),
    ]);
  };

  const handleSaveResult = (resultFileData, resultUploadFile) => {
    setLoading(false);

    if (resultFileData.type === "success" && resultUploadFile?.status === 200) {
      onClose("REFETCH");
      dispatch(
        setDialog({
          title: `${dialog.data.fileData.fileName} wurde erfolgreich gespeichert!`,
          type: "SUCCESS",
          data: {
            successText: resultFileData?.message,
          },
        })
      );
    } else {
      window.alert(
        "Es gab ein Problem beim Speichern der Datei.\nBitte versuchen Sie es erneut."
      );
    }
  };

  const handleSave = async (formData, action) => {
    setLoading(true);
    try {
      await checkInFileByID(dialog.data.fileData._id);
    } catch (error) {
      console.error(error);
    }
    const { revision } = formData;

    try {
      const exportedDocument =
        await documentEditorRef.current.documentEditor.saveAsBlob("Docx");
      const docFormData = new FormData();
      docFormData.append("fileName", dialog.data.fileData.fileName);
      docFormData.append("file", exportedDocument);

      if (!documentEditorRef.current) {
        setLoading(false);
        return;
      }

      let resultFileData, resultUploadFile;

      if ((isAdmin || isEditor) && revision) {
        // Admin is assigning the file back to a user to revise
        [resultFileData, resultUploadFile] = await handleAssignFile(
          docFormData,
          formData
        );
      }
      if (!isAdmin && !isEditor && revision) {
        // User is finished editing and sends the file back for revision
        console.log("User sends file to revision");
        [resultFileData, resultUploadFile] = await handleRevisionFile(
          docFormData,
          formData
        );
      }
      if (!revision) {
        // Either Admin or User wants to simply save the file
        [resultFileData, resultUploadFile] = await handleSaveFile(docFormData);
      }

      handleSaveResult(resultFileData, resultUploadFile);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      setLoading(false);
    }
  };

  const handleReadingConfirmation = async () => {
    setLoading(true);
    try {
      let updateResult = await updateFileData({
        _id: dialog.data.fileData._id,
        confirmReading: true,
      }).unwrap();
      // console.log("updateResult :>> ", updateResult);
      if (updateResult.type === "success") {
        onClose("REFETCH");
        dispatch(
          setDialog({
            title: `${dialog.data.fileData.fileName} gespeichert!`,
            type: "SUCCESS",
            data: {
              successText: "Dokument wurde als gelesen markiert.",
            },
          })
        );
      }
    } catch (error) {
      window.alert(
        "Es gab ein Problem beim Speichern der Datei.\nBitte versuchen Sie es erneut."
      );
    } finally {
      setLoading(false);
    }
  };

  // TODO: Pull up functionality from word excel and pdf viewer/editor up to this component
  // Use syncfusion components only for displaying

  React.useEffect(() => {
    if (hasFetched.current) {
      const handleCheckInDoc = async () => {
        try {
          let d = await checkInFileByID(dialog.data.fileData._id);
          if (d) {
            console.log("d:>>", d);
          }
        } catch (error) {
          console.error(error);
        }
      };
      // Define the event listener
      const handleBeforeUnload = (ev) => {
        ev.preventDefault();
        // console.log("Ask user");
        // ev.returnValue = "Are you sure you want to close?"; // Standard message for beforeunload
      };

      const handleUnload = () => {
        handleCheckInDoc(); // Ensure function tries to run on unload
        console.log("EXIT on unload");
      };

      const handlePageHide = (event) => {
        if (!event.persisted) {
          // Only if the page is being fully unloaded, not cached
          handleCheckInDoc();
          // console.log("EXIT on pagehide");
        }
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === "hidden") {
          if (navigator.sendBeacon) {
            navigator.sendBeacon(
              `${SERVER_URL}/public/filedata/checkInFileByID/${dialog.data.fileData._id}`,
              null
            );
          } else {
            // Fallback for browsers that don't support sendBeacon
            fetch(
              `${SERVER_URL}/public/filedata/checkInFileByID/${dialog.data.fileData._id}`,
              {
                method: "POST",
                body: null,
                headers: { "Content-Type": "application/json" },
                keepalive: true, // keepalive is supported in fetch API as a fallback
              }
            );
          }
        }
      };

      // Add the event listener
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("close", handleUnload);
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("pagehide", handlePageHide);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("pagehide", handlePageHide);
        window.removeEventListener("unload", handleUnload);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );

        handleCheckInDoc();
        // console.log("CLEAN UP!");
        setFile(null);
        documentEditorRef.current = null; // Clear the document editor ref
      };
    }
    async function fetchData() {
      try {
        let f;
        if (dialog?.data?.checkOut) {
          f = await checkOutFileByID(dialog.data.fileData._id); // Checkout file with id
        } else {
          f = await downloadFileByID(dialog.data.fileData._id); // Download file with id
        }
        setFile(f);
      } catch (error) {
        // Refresh token expired
        console.log("error :>> ", error);
        window.alert(
          "Ihre Sitzung ist abgelaufen, die Seite wird zur Sicherheit automatisch aktualisiert."
        );
        window.location.reload(false);
      }
    }
    // Only fetch data if dialog.data.fileData._id exists
    if (dialog.data.fileData._id) {
      fetchData();
    }
    hasFetched.current = true; // Set the flag to true after the effect runs
  }, [dialog.data]); // Dependency array with the file ID

  return (
    <Dialog
      fullScreen
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {dialog?.data?.fileData.fileName}
          </Typography>
          {!["SHOW", "CONFIRMATION"].includes(dialog?.data?.action) && (
            <div>
              {handleShowReleaseButton(
                isEditor,
                dialog?.data?.fileData,
                userData.id
              ) && (
                <ReleaseFileAdminDialog
                  fileData={dialog?.data?.fileData}
                  documentEditorRef={documentEditorRef}
                  loading={loading}
                />
              )}
              {isAdmin || isEditor ? (
                <UpdateFileAdminDialog
                  fileData={dialog?.data?.fileData}
                  action={dialog?.data?.action}
                  handleSave={handleSave}
                  loading={loading}
                />
              ) : (
                <UpdateFileUserDialog
                  fileData={dialog?.data?.fileData}
                  handleSave={handleSave}
                  loading={loading}
                />
              )}
            </div>
          )}
          {dialog?.data?.action === "CONFIRMATION" && (
            <Button
              autoFocus
              sx={{ ml: 2 }}
              variant="contained"
              color="secondary"
              onClick={handleReadingConfirmation}
              disabled={!read}
            >
              GELESEN
            </Button>
          )}
          <Button
            autoFocus
            sx={{ ml: 2 }}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            BEENDEN
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ height: "100%" }} onScrollCapture={handleScroll}>
        {dialog.data.fileData.fileName.includes(".doc") && file && (
          <DocumentEditor
            documentEditorRef={documentEditorRef}
            data={dialog?.data}
            file={file}
          />
        )}
        {dialog.data.fileData.fileName.includes(".xls") && (
          <SpreadsheetEditor data={dialog?.data} />
        )}
        {dialog.data.fileData.fileName.includes(".pdf") && (
          <PdfViewer data={dialog?.data} />
        )}
      </div>
      {dialog?.data?.action === "CONFIRMATION" && (
        <div
          style={{
            position: "absolute",
            bottom: 50,
            textAlign: "center",
            zIndex: 2000,
            width: "100%",
          }}
        >
          <Button
            autoFocus
            sx={{ ml: 2 }}
            variant="contained"
            color="secondary"
            onClick={handleReadingConfirmation}
            // disabled={!read}
          >
            ALS GELESEN MARKIEREN
          </Button>
        </div>
      )}
    </Dialog>
  );
}
