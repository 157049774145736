import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, MenuItem, Paper, TextField } from "@mui/material";
import Iconify from "../../../components/iconify";
import { surveySelectItems } from "../../../utils/surveyUtils";
import { Controller } from "react-hook-form";

SurveyFormQuestionItems.propTypes = {
  fields: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  watchQuestions: PropTypes.array.isRequired,
};

function SurveyFormQuestionItems({
  fields,
  remove,
  register,
  watchQuestions,
  control,
}) {
  return fields.map((surveyQuestions, index) => (
    <React.Fragment key={surveyQuestions.id}>
      {/* Question Input */}
      <Grid item xs={6} lg={6}>
        <TextField
          {...register(`surveyQuestions.${index}.question`, {
            required: "Frage ist erforderlich",
          })}
          label={`Frage ${index + 1}`}
          fullWidth
          multiline
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Question Type Select */}
      <Grid item xs={5} lg={5}>
        <Controller
          control={control}
          name={`surveyQuestions.${index}.type`}
          rules={{ required: "Bitte Art auswählen." }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              fullWidth
              variant="outlined"
              {...field}
              label="Antwortmöglichkeiten:"
              error={!!error}
              helperText={error?.message}
            >
              <MenuItem value="" disabled>
                Art der Bewertung auswählen
              </MenuItem>
              {surveySelectItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      {/* Remove Question Button */}
      <Grid item xs={1} lg={1}>
        <Button variant="contained" onClick={() => remove(index)} color="error">
          <Iconify
            icon="mdi:delete"
            sx={{
              m: 1,
              height: "25px",
              width: "25px",
            }}
          />
        </Button>
      </Grid>

      {/* Multiple Choice Options */}
      {watchQuestions[index]?.type === "multipleChoice" && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} elevation={1}>
            {/* Replace this with actual input logic for multiple-choice answers */}
            Hier entsteht ein Feld bei dem Antworten eingegeben werden können
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  ));
}

export default SurveyFormQuestionItems;
