import React, { useEffect, useState } from "react";
import {
  useGetFBQuery,
  useGetUserEntryFilterQuery,
} from "../features/entry/entryApiSlice";
import Header from "src/sections/@dashboard/entries/Header";
import EntryContentArea from "src/sections/@dashboard/entries/EntryContentArea";
import { Helmet } from "react-helmet-async";

// ----------------------------------------------------------------------

export default function EntryPage() {
  const { data: dataFB } = useGetFBQuery();
  const { data: entryFilter } = useGetUserEntryFilterQuery();
  const [selectedItem, setSelectedItem] = useState(null);

  const [items, setItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);
  const [years, setYears] = useState([]);
  const [alignment, setAlignment] = useState("alle");
  const [filterInput, setFilterInput] = useState("");

  useEffect(() => {
    if (!dataFB) return;

    // Compute items based on alignment
    let updatedItems = [];
    if (alignment === "alle") {
      updatedItems = [...dataFB].reverse();
    } else {
      updatedItems = dataFB
        .filter((item) => item.incidentType === alignment)
        .reverse();
    }
    setItems(updatedItems);

    // Optionally compute sortedItems and years
    const filteredItems = updatedItems.filter((item) =>
      item.title.toLowerCase().includes(filterInput.toLowerCase())
    );
    setSortedItems(filteredItems);

    const uniqueYears = [
      ...new Set(
        filteredItems.map((item) => new Date(item.createdAt).getFullYear())
      ),
    ];
    setYears(uniqueYears.sort((a, b) => b - a));
  }, [dataFB, entryFilter, alignment, filterInput]);

  return (
    <div style={{ textAlign: "center", height: "90vh", overflow: "hidden" }}>
      <Helmet>
        <title> Digit QM | F. B. R.</title>
      </Helmet>
      <Header
        items={items}
        filterInput={filterInput}
        setFilterInput={setFilterInput}
        setSortedItems={setSortedItems}
        setYears={setYears}
        entryFilter={entryFilter}
        setSelectedItem={setSelectedItem}
      />
      <EntryContentArea
        years={years}
        sortedItems={sortedItems}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
}
