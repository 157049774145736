import React from "react";
import { Button, TextField, Grid, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "../../../components/iconify";
import SurveyFormQuestionItems from "./SurveyFormQuestionItems";
import ControlledSelectSurveyType from "../../../components/forms/Forms/SurveyForm/ControlledSelectSurveyType";
import SurveyProtectionCheckbox from "../../../components/forms/Forms/SurveyForm/SurveyProtectionCheckbox";

CreateSurveyForm.propTypes = {
  register: PropTypes.func,
  control: PropTypes.func,
  errors: PropTypes.object,
};

function CreateSurveyForm({
  register,
  control,
  fields,
  append,
  remove,
  watchQuestions,
  errors,
}) {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ControlledSelectSurveyType control={control} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            margin="normal"
            label="Titel der Befragung"
            error={!!errors.surveyTitle}
            {...register("surveyTitle", {
              required: "Dieses Feld ist erforderlich",
            })}
          />
          {errors.surveyTitle && (
            <FormHelperText error>{errors.surveyTitle.message}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Beschreibung"
            {...register("surveyDescription")}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <SurveyProtectionCheckbox control={control} />
        </Grid>
        <SurveyFormQuestionItems
          fields={fields}
          remove={remove}
          register={register}
          watchQuestions={watchQuestions}
          control={control}
        />
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => append({})}
            startIcon={<Iconify icon="tabler:plus" />}
          >
            Frage hinzufügen
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

CreateSurveyForm.propTypes = {};

export default CreateSurveyForm;
