import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";

const UserRolesBadge = ({ status }) => {
  const getBadgeProps = () => {
    switch (status) {
      case "SuperAdmin":
        return { label: "SuperAdmin", color: "error" };
      case "Admin":
        return { label: "Admin", color: "warning" };
      case "Editor":
        return { label: "Editor", color: "primary" };
      case "User":
        return { label: "User", color: "default" };
      default:
        return { label: "Unknown", color: "default" };
    }
  };

  const { label, color } = getBadgeProps();

  return <Chip label={label} color={color} variant="outlined" size="small" />;
};

UserRolesBadge.propTypes = {
  status: PropTypes.oneOf(["Admin", "Editor", "User"]),
};

export default UserRolesBadge;
