import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Stack,
} from "@mui/material";
import { useGetEventsQuery } from "../features/event/eventApiSlice";
import { Helmet } from "react-helmet-async";
import {
  Agenda,
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
} from "@syncfusion/ej2-react-schedule";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import numbers from "cldr-data/main/de/numbers.json";
import gregorian from "cldr-data/main/de/ca-gregorian.json";
import timeZoneNames from "cldr-data/main/de/timeZoneNames.json";
import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import { useDispatch } from "react-redux";
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";

// Lokalisierung laden
loadCldr(numbers, gregorian, timeZoneNames, numberingSystems);
L10n.load({
  de: {
    schedule: {
      day: "Tag",
      week: "Woche",
      workWeek: "Arbeitswoche",
      month: "Monat",
      agenda: "Agenda",
      today: "Heute",
      noEvents: "Keine Ereignisse",
      emptyContainer: "Keine Ereignisse an diesem Tag.",
      allDay: "Ganztägig",
      start: "Start",
      end: "Ende",
      more: "Mehr",
      close: "Schließen",
      cancel: "Abbrechen",
      save: "Speichern",
      delete: "Löschen",
      edit: "Bearbeiten",
    },
  },
});

export default function CalendarPage() {
  const dispatch = useDispatch();
  const { data: events } = useGetEventsQuery();
  const [scheduleData, setScheduleData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getRecurrenceRule = (recurrence) => {
    if (!recurrence || !recurrence.frequency) {
      return null; // Keine Wiederholungsregel
    }

    let rule = `FREQ=${recurrence.frequency.toUpperCase()};`;

    if (recurrence.interval && recurrence.interval !== 1) {
      rule += `INTERVAL=${recurrence.interval};`;
    }

    if (recurrence.byDay && recurrence.byDay.length > 0) {
      rule += `BYDAY=${recurrence.byDay.join(",")};`;
    }

    // Priorisiere Endbedingungen: `count` > `endBy` > keine Einschränkung
    if (recurrence.count) {
      rule += `COUNT=${recurrence.count};`; // Anzahl der Wiederholungen
    } else if (recurrence.endBy) {
      const endByDate = new Date(recurrence.endBy).toISOString().split("T")[0];
      rule += `UNTIL=${endByDate.replace(/-/g, "")}T000000Z;`; // ISO 8601 Format
    }

    return rule; // Rückgabe der fertigen Regel
  };

  useEffect(() => {
    if (events) {
      const transformedEvents = events.map((event) => {
        const recurrenceRule = getRecurrenceRule(event.recurrence);

        console.log("event.recurrence", event.recurrence);
        console.log("recurrenceRule", recurrenceRule);
        return {
          Id: event._id,
          Subject: event.title,
          Creator: event.creator,
          StartTime: new Date(event.start),
          EndTime: new Date(event.end || event.start),
          IsAllDay: event.allDay || false,
          Description: event.description,
          History: event.history,
          officeRef: event.officeRef,
          Tenant: event.tenant,
          User: event.user,
          Location: event.location,
          CategoryColor: event?.color || "#0000FF",
          recurrence: event.recurrence,
          RecurrenceRule: recurrenceRule,
          RecurrenceException:
            event.recurrence?.recurrenceException?.join(",") || null,
        };
      });
      setScheduleData(transformedEvents);
    }
  }, [events]);

  // Popup öffnen und eigenen Dialog anzeigen
  const onPopupOpen = (args) => {
    if (args.type === "Editor") {
      console.log("args", args.editType);
      args.cancel = true; // Standard-Popup verhindern
      console.log("argsInCalendar", args);
      const eventFlag = args.data?.Id ? "EDIT" : "CREATE";
      dispatch(
        setDialog({
          content: "EventDialog",
          data: { ...args.data, editType: args.editType },
          eventFlag: eventFlag,
        })
      );
    }
  };

  const handleNewEventClick = () => {
    dispatch(
      setDialog({ content: "EventDialog", data: null, eventFlag: "NEW" })
    );
  };

  // Event Rendering für Farbanpassung
  const onEventRendered = (args) => {
    if (args.data?.CategoryColor) {
      args.element.style.backgroundColor = args.data.CategoryColor;
    }
  };

  const quickInfoFooterTemplate = (props) => {
    const isRecurring = props?.RecurrenceRule; // Prüfen ob es sich um einen Serientermin handelt

    return (
      <div>
        <Typography
          variant="subtitle2"
          color="primary"
          onClick={() =>
            onPopupOpen({
              type: "Editor",
              data: props,
              editType: isRecurring ? "editOneEventFromSerie" : "normal",
            })
          }
          style={{ cursor: "pointer" }}
        >
          {isRecurring ? "Veranstaltung bearbeiten" : "Details anzeigen"}
        </Typography>
        {isRecurring && (
          <Typography
            variant="subtitle2"
            color="secondary"
            onClick={() =>
              onPopupOpen({
                type: "Editor",
                data: props,
                editType: "editHoleSerie",
              })
            }
            style={{ cursor: "pointer", marginTop: "10px" }}
          >
            Serie bearbeiten
          </Typography>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Kalender</title>
      </Helmet>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginLeft: "10px" }}
      >
        <Typography variant={isMobile ? "h5" : "h4"} sx={{ mb: 3 }}>
          Kalender
        </Typography>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            width: isMobile ? "100%" : "auto",
            marginBottom: isMobile ? "10px" : "0px",
          }}
          onClick={() => handleNewEventClick()}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Neuer Termin
        </Button>
      </Stack>
      <ScheduleComponent
        height="auto"
        width="100%"
        firstDayOfWeek={1}
        locale="de"
        rowAutoHeight={true}
        currentView="Month"
        showHeaderBar={true}
        enableAdaptiveUI={false}
        eventSettings={{ dataSource: scheduleData }}
        popupOpen={onPopupOpen}
        eventRendered={onEventRendered}
        quickInfoTemplates={{
          footer: quickInfoFooterTemplate,
        }}
      >
        <ViewsDirective>
          <ViewDirective option="Day" startHour="5:00" endHour="22:00" />
          <ViewDirective option="Week" startHour="5:00" endHour="22:00" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
        <Inject services={[Day, Week, Month, Agenda]} />
      </ScheduleComponent>
    </Box>
  );
}
