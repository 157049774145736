import React from "react";
import { Grid } from "@mui/material";
import TextField from "../../../components/forms/TextField";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";
import MultiSelectController from "../../../components/forms/MultiSelectUsersController";
import DEDateField from "../../../components/forms/DEDateField";
import DETimeField from "../../../components/forms/DETimeField";
import ControlledColorComponent from "src/components/forms/ControlledColorComponent";
import ControlledSelectField from "../../../components/forms/ControlledSelectField";
import CounterField from "../../../components/forms/CounterField";
import ControlledChipGroup from "../../../components/forms/ControlledChipGroup";

function EventForm({ control, allDay, isRecurring, watch }) {
  const recurrenceFrequency = watch("recurrence.frequency");
  const recurrenceEndType = watch("recurrence.recurrenceEndType");

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="title"
            label="Titel"
            required
            multiline
            errorMsg="Bitte Titel eingeben!"
            fullWidth
          />
        </Grid>

        <Grid
          container
          item
          spacing={1}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid item xs={allDay ? 6 : 3.5}>
            <DEDateField
              control={control}
              name="start"
              label="Startdatum"
              errorMsg="Bitte wähle ein Datum aus"
              required={true}
            />
          </Grid>
          {!allDay && (
            <>
              <Grid item xs={2.5}>
                <DETimeField
                  control={control}
                  name="startTime"
                  label="Startzeit"
                  errorMsg="Bitte wähle eine Uhrzeit aus"
                  required={true}
                />
              </Grid>
              <Grid item xs={2.5}>
                <DETimeField
                  control={control}
                  name="endTime"
                  label="Endzeit"
                  errorMsg="Bitte wähle eine Uhrzeit aus"
                  required={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={allDay ? 6 : 3.5}>
            <DEDateField
              control={control}
              name="end"
              label="Enddatum"
              errorMsg="Bitte wähle ein Datum aus"
              required={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <ControlledCheckbox
            control={control}
            name="allDay"
            label="Ganztägig"
            description=""
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectController
            control={control}
            name="user"
            label="Personen"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledColorComponent
            control={control}
            name="color"
            label="Farbe"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            control={control}
            name="description"
            label="Beschreibung"
            variant="outlined"
            errorMsg="Bitte Beschreibung angeben"
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="location"
            label="Ort"
            variant="outlined"
            errorMsg="Bitte Beschreibung angeben"
            multiline
            fullWidth
          />
        </Grid>

        {/* Wiederholungsoptionen */}
        <Grid item xs={12}>
          <ControlledCheckbox
            control={control}
            name="isRecurring"
            label="Wiederholen"
            description="Soll das Event wiederholt werden?"
          />
        </Grid>

        {isRecurring && (
          <>
            <Grid item xs={4}>
              <ControlledSelectField
                control={control}
                name="recurrence.frequency"
                label="Frequenz"
                itemArray={[
                  { value: "daily", label: "Täglich" },
                  { value: "weekly", label: "Wöchentlich" },
                  { value: "monthly", label: "Monatlich" },
                  { value: "yearly", label: "Jährlich" },
                ]}
              />
            </Grid>

            {recurrenceFrequency === "daily" && (
              <Grid item xs={4}>
                <CounterField
                  control={control}
                  name="recurrence.interval"
                  label="Alle wie viele Tage?"
                  type="number"
                  fullWidth
                />
              </Grid>
            )}

            {recurrenceFrequency === "weekly" && (
              <>
                <Grid item xs={4}>
                  <CounterField
                    control={control}
                    name="recurrence.interval"
                    label="Alle wie viele Wochen?"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <ControlledChipGroup
                    control={control}
                    name="recurrence.byDay" // Dein Zustand (z.B. Wochentage)
                    label="Wochentage auswählen"
                    itemArray={[
                      { value: "MO", label: "Montag" },
                      { value: "TU", label: "Dienstag" },
                      { value: "WE", label: "Mittwoch" },
                      { value: "TH", label: "Donnerstag" },
                      { value: "FR", label: "Freitag" },
                      { value: "SA", label: "Samstag" },
                      { value: "SU", label: "Sonntag" },
                    ]}
                    watch={watch}
                  />
                </Grid>
              </>
            )}

            {recurrenceFrequency === "monthly" && (
              <Grid item xs={4}>
                <CounterField
                  control={control}
                  name="recurrence.interval"
                  label="Alle wie viele Monate?"
                  type="number"
                  fullWidth
                />
              </Grid>
            )}

            {recurrenceFrequency === "yearly" && (
              <>
                <Grid item xs={4}>
                  <CounterField
                    control={control}
                    name="recurrence.interval"
                    label="Alle wie viele Jahre?"
                    type="number"
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {/* End Options */}
            <Grid item xs={6}>
              <ControlledSelectField
                control={control}
                name="recurrence.recurrenceEndType"
                label="Ende"
                itemArray={[
                  { value: "never", label: "Nie" },
                  { value: "date", label: "Datum" },
                  { value: "count", label: "Anzahl der Wiederholungen" },
                ]}
              />
            </Grid>
            {recurrenceEndType === "date" && (
              <Grid item xs={6}>
                <DEDateField
                  control={control}
                  name="recurrence.endBy"
                  label="Enddatum"
                  required
                />
              </Grid>
            )}
            {recurrenceEndType === "count" && (
              <Grid item xs={6}>
                <CounterField
                  control={control}
                  name="recurrence.count"
                  label="Anzahl der Wiederholungen"
                  type="number"
                  fullWidth
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}

export default EventForm;
