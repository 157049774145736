import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import Iconify from "../../../../components/iconify";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { getSocket } from "../../../../app/socket/socket";
import { useGetUsersWithoutChatQuery } from "../../../../features/chats/chatsApiSlice";
import ControlledToggleButtonGroup from "../../../../components/forms/ControlledToggleButtonGroup";
import CreateChatUserForm from "./CreateChatUserForm";
import CreateGroupForm from "./CreateGroupForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ChatDialog({ handleClose }) {
  const currentUserData = useSelector(selectCurrentUserData);
  const currentUserId = currentUserData?.id;
  const { data: allUsers } = useGetUsersWithoutChatQuery();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      chatMode: "user",
      groupName: "",
      users: [],
      message: "",
    },
  });

  const mode = watch("chatMode");
  const users = watch("users");
  const message = watch("message");

  useEffect(() => {
    setFilteredUsers(allUsers || []);
  }, [allUsers]);

  useEffect(() => {
    setValue("users", []); // Reset users to an empty array
    setValue("message", ""); // Reset message field
    if (!mode) {
      setValue("groupName", ""); // Reset group name if switching to single user mode
    }
  }, [mode, setValue]);

  const onSubmit = async (data) => {
    const socket = getSocket();

    console.log("data", data);
    const chatData = {
      currentUserId,
      users: data.users,
      message: data.message,
      tenant: currentUserData?.tenant,
      chatMode: data.chatMode, // Include chatMode flag
    };

    if (data.chatMode === "group") {
      chatData.groupName = data.groupName; // Include group name if it's a group chat
    }

    socket.emit("create-chat", chatData, (response) => {
      if (response.success) {
        handleClose("REFETCH");
      } else {
        console.error("Error creating chat:", response.error);
      }
    });
  };

  return (
    <BootstrapDialog open onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          {mode ? "Gruppe erstellen" : "Chat erstellen"}
        </Typography>
        <Iconify
          icon="mingcute:close-line"
          onClick={handleClose}
          style={{ fontSize: "30px", cursor: "pointer" }}
        />
      </Box>

      <Stack sx={{ p: 2 }}>
        <DialogContent>
          <ControlledToggleButtonGroup
            control={control}
            name="chatMode"
            fullWidth
            options={[
              { label: "Benutzer", value: "user" },
              { label: "Gruppe", value: "group" },
            ]}
          />

          {mode === "group" ? (
            <CreateGroupForm control={control} filteredUsers={filteredUsers} />
          ) : (
            <CreateChatUserForm
              control={control}
              filteredUsers={filteredUsers}
              users={users}
            />
          )}
        </DialogContent>
      </Stack>

      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Abbrechen
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          sx={{
            textTransform: "none",
          }}
          color="secondary"
          variant="contained"
          disabled={!(users !== null && message !== null)}
        >
          {mode ? "Gruppe erstellen" : "Chat erstellen"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

ChatDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
