import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import { useGetTenantInformationQuery } from "../../../features/tenants/tenantsApiSlice";
import Loading from "../../../components/spinners/Loading";
import {
  getCurrentOfficesCount,
  getCurrentUserCount,
} from "../../../utils/tenantUtils";
import ModuleAddDialog from "./ModuleAddDialog";

const ModuleSettings = () => {
  const { data: tenant, isLoading: isLoadingTenant } =
    useGetTenantInformationQuery();

  return (
    <Box sx={{ p: 1 }}>
      {isLoadingTenant && <Loading />}
      <Typography variant="h3" sx={{ mb: 2 }}>
        Module
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Typography variant="h4" gutterBottom>
        Hier sehen Sie eine Übersicht der von Ihnen gebuchten Module:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ minWidth: 275, borderRadius: 3, minHeight: 250 }}>
            <CardHeader
              title={`Gebuchte Benutzer: ${getCurrentUserCount(tenant)}`}
              titleTypographyProps={{ variant: "h4", textAlign: "center" }}
            />
            <CardContent>
              <Typography>
                {`Um weitere Benutzer freizuschalten unten auf BUCHEN klicken`}
              </Typography>
            </CardContent>
            <CardActions
              style={{ justifyContent: "space-between" }}
              disableSpacing
            >
              <ModuleAddDialog tenant={tenant} module={"USER"} />
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ minWidth: 275, borderRadius: 3, minHeight: 250 }}>
            <CardHeader
              title={`Gebuchte Praxen: ${getCurrentOfficesCount(tenant)}`}
              titleTypographyProps={{ variant: "h4", textAlign: "center" }}
            />
            <CardContent>
              <Typography>
                {`Um weitere Praxen freizuschalten unten auf BUCHEN klicken`}
              </Typography>
            </CardContent>
            <CardActions
              style={{ justifyContent: "space-between" }}
              disableSpacing
            >
              <ModuleAddDialog tenant={tenant} module={"OFFICE"} />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModuleSettings;
