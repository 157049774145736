import React from "react";
import { Typography, Grid, Paper, Divider } from "@mui/material";
import { getDateFromString } from "../../../../utils/dateUtils";
import FileDataResponsibleUserSelect from "../../Fields/FileDataResponsibleUserSelect";
import SimpleTextField from "../../Fields/SimpleTextField";
import SimpleSelectField from "../../Fields/SimpleSelectField";

function AuditItemForm({ auditItem, control, type }) {
  return (
    <Paper elevation={0}>
      <Typography variant="h5" gutterBottom>
        Auditpunkt
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      {/* todo: Praxisbezogen oder allgemein */}
      {/* Grundlegende Informationen */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Zeitraum/-punkt
          </Typography>
          <Grid item xs={12}>
            <SimpleTextField
              control={control}
              name={"period"}
              label={"Zeitraum/-punkt"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Aufgaben/Termine
          </Typography>
          <Grid item xs={12}>
            <SimpleTextField
              control={control}
              name={"task"}
              label={"Aufgaben/Termine"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2 }} />
      <Typography variant="h6" gutterBottom>
        Status
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SimpleSelectField
            control={control}
            name="status"
            label="Status"
            options={[
              { value: "open", label: "Offen" },
              { value: "inProgress", label: "In Bearbeitung" },
              { value: "completed", label: "Erledigt" },
            ]}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      {/* Verantwortliche und Ersteller */}
      {type === "CREATE" && (
        <>
          <Typography variant="h6" gutterBottom>
            Verantwortlich
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FileDataResponsibleUserSelect control={control} />
            </Grid>
          </Grid>
        </>
      )}
      {type === "EDIT" && (
        <>
          <Divider sx={{ marginY: 2 }} />

          {/* Weitere Details */}
          <Typography variant="h6" gutterBottom>
            Weitere Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Erstellt am:</strong>{" "}
                {getDateFromString(auditItem?.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Zuletzt geändert:</strong>{" "}
                {getDateFromString(auditItem?.updatedAt)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
}

export default AuditItemForm;
