// import React, { useState, useEffect } from "react";
// import EntryList from "../entries/EntryList";
// import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
// import Iconify from "src/components/iconify";
// import {
//   Outlet,
//   Route,
//   Routes,
//   useNavigate,
//   useLocation,
// } from "react-router-dom";
// import MySlideSnackbar from "../../../components/snackbar/MySlideSnackbar";
// import EntryDetailLayout from "./EntryDetailLayout";

// export default function EntryContentArea({
//   years,
//   sortedItems,
//   selectedItem,
//   setSelectedItem,
// }) {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [msg, setMsg] = useState("");

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleSelectItem = (item) => {
//     navigate(`/dashboard/entry/${item._id}`);
//     setSelectedItem(item);
//   };

//   // Determine if we are on a detail page
//   const isEntryDetailView =
//     location.pathname.includes("/dashboard/entry/") &&
//     location.pathname.includes(":id");

//   return (
//     <div
//       style={{
//         display: "flex",
//         width: "100%",
//         maxHeight: "80vh", // Full viewport height
//         marginTop: "20px",
//         flexDirection: isMobile ? "column" : "row", // Column for mobile, row for larger screens
//         overflow: "hidden", // Prevent overall page scroll, just scroll sections
//       }}
//     >
//       <Grid container spacing={0} style={{ width: "100%", height: "100%" }}>
//         {/* Left Side: EntryList */}
//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             height: "70vh",
//             overflowY: "hidden", // Standard: Scroll-Leiste versteckt
//             "&:hover": {
//               overflowY: "auto", // Scroll-Leiste wird bei Hover sichtbar
//             },
//             transition: "overflow-y 0.2s ease-in-out", // Sanfter Übergang
//             "&::-webkit-scrollbar": {
//               width: "8px", // Breite der Scroll-Leiste
//             },
//             "&::-webkit-scrollbar-thumb": {
//               backgroundColor: "rgba(0, 0, 0, 0.3)", // Stil des Scrollbar-Thumbnails
//               borderRadius: "4px", // Abgerundete Ecken
//             },
//             "&::-webkit-scrollbar-thumb:hover": {
//               backgroundColor: "rgba(0, 0, 0, 0.5)", // Hover-Stil
//             },
//           }}
//         >
//           {/* Entry List only shows on mobile or when not in the detail view */}
//           {!isMobile || location.pathname === "/dashboard/entry" ? (
//             <EntryList
//               years={years}
//               sortedItems={sortedItems}
//               handleSelectItem={handleSelectItem}
//               selectedItem={selectedItem}
//               style={{
//                 width: "100%",
//                 margin: 0,
//               }}
//             />
//           ) : null}
//         </Grid>

//         {/* Right Side: EntryDetailLayout */}
//         <Grid
//           item
//           xs={12}
//           md={8}
//           style={{
//             padding: 0,
//             height: "70vh",
//             height: "100vh", // Full height for mobile and desktop
//             overflowY: "auto", // Allow scrolling if content overflows
//           }}
//         >
//           {/* Detail Layout shows when mobile and in the detail view */}
//           {isMobile && isEntryDetailView ? (
//             <EntryDetailLayout />
//           ) : (
//             <Routes>
//               <Route
//                 index
//                 element={
//                   <Box
//                     display="flex"
//                     alignItems="center"
//                     justifyContent="center"
//                     height="100%"
//                   >
//                     <Typography variant="h6" sx={{ textAlign: "center" }}>
//                       <Iconify
//                         icon="line-md:list-3"
//                         sx={{
//                           color: "text.disabled",
//                           width: 40,
//                           height: 40,
//                           marginRight: 1,
//                         }}
//                       />
//                       Wählen Sie ein Element aus der Liste aus.
//                     </Typography>
//                   </Box>
//                 }
//               />
//               <Route path=":id" element={<EntryDetailLayout />} />
//             </Routes>
//           )}
//           <Outlet />
//         </Grid>
//       </Grid>

//       {/* Snackbar for success messages */}
//       <MySlideSnackbar
//         open={snackbarOpen}
//         onClose={() => setSnackbarOpen(false)}
//         message={msg}
//       />
//     </div>
//   );
// }

import React, { useState } from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import EntryList from "../entries/EntryList";
import EntryDetailLayout from "./EntryDetailLayout";
import Iconify from "src/components/iconify";

export default function EntryContentArea({
  years,
  sortedItems,
  selectedItem,
  setSelectedItem,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSelectItem = (item) => {
    navigate(`/dashboard/entry/${item._id}`); // Navigiert zur Detailansicht
    setSelectedItem(item);
  };

  const isEntryDetailView = location.pathname.includes("/dashboard/entry/");

  return (
    <Grid
      container
      spacing={0}
      style={{
        width: "100%",
        height: "100%",
        flexDirection: isMobile && isEntryDetailView ? "column" : "row",
        overflow: "hidden",
      }}
    >
      {/* Linke Liste (nur sichtbar, wenn nicht in der Detailansicht oder nicht auf einem mobilen Gerät) */}
      {!isMobile || !isEntryDetailView ? (
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: "70vh",
            overflowY: "scroll", // Scrollbar wird immer angezeigt
            transition: "overflow-y 0.2s ease-in-out",
            "&:hover": { overflowY: "auto" }, // Automatischer Scroll auf Hover
          }}
        >
          <EntryList
            years={years}
            sortedItems={sortedItems}
            handleSelectItem={handleSelectItem}
            selectedItem={selectedItem}
          />
        </Grid>
      ) : null}

      {/* Rechte Detailansicht */}
      <Grid
        item
        xs={12}
        md={8}
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        {isMobile && isEntryDetailView ? (
          <Routes>
            <Route path=":id" element={<EntryDetailLayout />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              index
              element={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    <Iconify
                      icon="line-md:list-3"
                      sx={{
                        color: "text.disabled",
                        width: 40,
                        height: 40,
                        marginRight: 1,
                      }}
                    />
                    Wählen Sie ein Element aus der Liste aus.
                  </Typography>
                </Box>
              }
            />
            <Route path=":id" element={<EntryDetailLayout />} />
          </Routes>
        )}
      </Grid>
    </Grid>
  );
}
