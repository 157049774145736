import React from "react";
import PropTypes from "prop-types";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import CreateSurveyForm from "./CreateSurveyForm";
import Iconify from "../../../components/iconify";
import {
  useAddNewSurveyMutation,
  useUpdateSurveyMutation,
} from "../../../features/surveys/surveysApiSlice";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

// ---------------------------------------------------------------------------------------------------------

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

CreateSurveyDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

// --------------------------------------------------------------------------------------------------------------

export default function CreateSurveyDialog({ dialog, handleClose }) {
  const dispatch = useDispatch();
  const [addNewSurvey] = useAddNewSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();

  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { ...dialog?.survey },
  });

  console.log("dialog :>> ", dialog);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "surveyQuestions",
  });

  const isEdit = dialog?.type === "EDIT";

  const watchQuestions = watch("surveyQuestions");

  const onSubmit = async (data) => {
    data.surveyQuestions = data.surveyQuestions.map((q, index) => ({
      ...q,
      index,
    }));
    console.log("data :>> ", data);

    try {
      let result;
      if (isEdit) {
        result = await updateSurvey(data).unwrap();
      } else {
        result = await addNewSurvey(data).unwrap();
      }
      console.log("result :>> ", result);
      if (result?.type === "success") {
        dispatch(
          setDialog({
            title: "Neue Befragung erstellt!",
            type: "SUCCESS",
            data: {
              successText: result?.message ?? "",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      if (error?.data?.duplicate) {
        console.log("EEEEEEORRR");
        setError("surveyTitle", {
          type: "custom",
          message: "Eine Befragung mit diesem Titel existiert bereits.",
        });
      }
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open
      fullWidth={true}
      maxWidth="lg"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          id="customized-dialog-title"
        >
          {dialog?.title}
        </Typography>
        <Box sx={{ flex: "0 0 auto" }}>
          <Iconify
            onClick={handleClose}
            icon="mingcute:close-line"
            style={{ fontSize: "50px", color: "action.active" }}
            sx={{
              transition: "transform 0.1s", // Smooth transition for transform
              "&:hover": {
                transform: "scale(1.8)", // Scale up the icon when hovered
              },
            }}
          />
        </Box>
      </Box>

      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreateSurveyForm
            register={register}
            control={control}
            fields={fields}
            append={append}
            remove={remove}
            watchQuestions={watchQuestions}
            errors={errors}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ background: "#9E9E9E" }}
          variant="contained"
          onClick={handleClose}
        >
          Abbrechen
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
        >
          {isEdit ? "Speichern" : "erstellen"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
