import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";

function FileDataAllOfficesCheckbox({ control }) {
  return (
    <>
      <Controller
        name="allOffices"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label="Praxisübergreifendes Dokument"
          />
        )}
      />
      <FormHelperText>
        Wenn dieser Wert ausgewählt ist, ist das Dokument für alle Praxen im
        Verbund verfügbar.
      </FormHelperText>
    </>
  );
}

FileDataAllOfficesCheckbox.propTypes = {
  control: PropTypes.object,
};

export default FileDataAllOfficesCheckbox;
