import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { Box, List, Button } from "@mui/material";
import UserItem from "./UserItem";
import { useGetChatsQuery } from "../../../features/chats/chatsApiSlice";
import { getSocket } from "../../../app/socket/socket";
import Iconify from "../../../components/iconify";

const ChatList = ({ onSelectChat }) => {
  const dispatch = useDispatch();

  const {
    data: chats,
    error: chatError,
    isLoading: chatLoading,
    refetch,
  } = useGetChatsQuery();

  useEffect(() => {
    const socket = getSocket();
    socket.on("refreshChats", () => {
      refetch();
    });

    return () => {
      socket.off("refreshChats");
    };
  }, [refetch]);

  if (chatLoading) {
    return <div>Loading...</div>;
  }

  if (chatError) {
    return <div>Error: {chatError}</div>;
  }

  const handleCreateChat = () => {
    dispatch(
      setDialog({
        content: "ChatDialog",
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Button
          onClick={() => handleCreateChat()}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Neuer Chat
        </Button>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          height: 0,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <List>
          {chats?.map((chat, index) => (
            <UserItem
              key={chat._id || index}
              chat={chat}
              onClick={() => onSelectChat(chat)}
              onSelectChat={onSelectChat}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ChatList;
