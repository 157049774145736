import React from "react";
import PropTypes from "prop-types";
import FolderTree from "./FolderTree";
import { useGetFileStructureQuery } from "../../../features/files/filesApiSlice";
import Iconify from "../../../components/iconify";
import { Button, Typography } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

function DisplayFolderStructure({
  handleOpenFileDialog,
  handleOpenAssignFileDialog,
}) {
  const dispatch = useDispatch();

  const { data: fileStructure } = useGetFileStructureQuery();

  const handleOpenInitializeDialog = () => {
    dispatch(
      setDialog({
        title: "QM Dokumentenstruktur erstellen",
        type: "CREATE",
        content: "InitializeQMFolder",
      })
    );
  };

  const handleOpenDeleteQMStructureDialog = () => {
    dispatch(
      setDialog({
        title: "QM Dokumentenstruktur löschen",
        type: "DELETE",
        content: "DeleteQMFolderStructure",
      })
    );
  };

  if (!fileStructure || !fileStructure?.length > 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <Iconify icon={"clarity:file-group-solid"} width={250} height={250} />
        </div>
        <Typography variant="h3">
          Noch keine QM-Dokumenten-Struktur vorhanden.
        </Typography>
        <div>
          <Button
            onClick={handleOpenInitializeDialog}
            variant="contained"
            sx={{ minWidth: 300, fontSize: "125%", m: 3 }}
            startIcon={<Iconify icon="clarity:file-group-solid" />}
          >
            QM-Dokumenten-Struktur erstellen
          </Button>
          <Button
            onClick={handleOpenDeleteQMStructureDialog}
            color="error"
            variant="contained"
          >
            ORDNERSTRUKTUR ZURÜCKSETZEN
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <FolderTree
        data={fileStructure}
        handleOpenFileDialog={handleOpenFileDialog}
        handleOpenAssignFileDialog={handleOpenAssignFileDialog}
      />
      <Button
        onClick={handleOpenDeleteQMStructureDialog}
        color="error"
        variant="contained"
      >
        ORDNERSTRUKTUR LÖSCHEN
      </Button>
    </div>
  );
}

DisplayFolderStructure.propTypes = {
  handleOpenFileDialog: PropTypes.func,
  handleOpenAssignFileDialog: PropTypes.func,
};

export default DisplayFolderStructure;
