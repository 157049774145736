import React from "react";
import SupportRequests from "./SupportRequestsTable";
import { useGetSupportsQuery } from "../../../features/support/supportApiSlice";

function SupportOverview() {
  const { data: supportRequests, isLoading } = useGetSupportsQuery();
  return (
    <div>
      {supportRequests && !isLoading && (
        <SupportRequests supportRequests={supportRequests} />
      )}
    </div>
  );
}

export default SupportOverview;
