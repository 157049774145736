import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Iconify from "src/components/iconify";
import { setDialog } from "src/features/dialog/dialogSlice";
import EntryFilterSidebar from "./EntryFilterSidebar";

// ----------------------------------------------------------------------

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  padding: "0px 12px",
  height: 50,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `2px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function Header({
  items,
  filterInput,
  setFilterInput,
  setSortedItems,
  setYears,
  entryFilter,
  setSelectedItem,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State to manage the visibility of the EntryFilterSidebar
  const [openFilter, setOpenFilter] = useState(false);

  const handleClickOpen = () => {
    dispatch(setDialog({ content: "CreateFBForm" }));
  };

  const handleFilterInputChange = (event) => {
    setFilterInput(event.target.value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  //  useEffect filtert die Suche - Suchenfunktion
  useEffect(() => {
    if (!items) return;

    const filteredItems = items.filter((item) => {
      setSelectedItem(null);
      const itemDate = item.recognisedOn
        ? new Date(item.recognisedOn).toLocaleDateString()
        : "";

      const searchableString = `${item.title} 
        ${item.description} 
        ${item.incidentType} 
        ${itemDate} 
        ${item.creator.firstName} 
        ${item.creator.lastName} 
        ${item?.responsibleUser?.firstName || ""} 
        ${item?.responsibleUser?.lastName || ""} 
        `;

      return searchableString.toLowerCase().includes(filterInput.toLowerCase());
    });

    setSortedItems(filteredItems);

    const uniqueYears = [
      ...new Set(
        filteredItems.map((item) => new Date(item.createdAt).getFullYear())
      ),
    ];
    setYears(uniqueYears.sort((a, b) => b - a));
  }, [items, filterInput]);

  return (
    <>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4" sx={{ mb: isMobile ? 2 : 0 }}>
          Fehler-, Beschwerde- und Risikomanagement
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ width: isMobile ? "100%" : "auto", mb: isMobile ? 2 : 0 }}
        >
          Neuer Eintrag
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "2%",
        }}
      >
        <StyledSearch
          value={filterInput}
          onChange={handleFilterInputChange}
          placeholder="Suche..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
          sx={{ width: isMobile ? "100%" : "auto", mb: isMobile ? 2 : 0 }}
        />
        <EntryFilterSidebar
          openFilter={openFilter}
          onOpenFilter={handleOpenFilter}
          onCloseFilter={handleCloseFilter}
          filter={entryFilter?.entryFilter}
          setSelectedItem={setSelectedItem}
        />
      </Box>
    </>
  );
}
