import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

function SimpleTextField({ control, name, label }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
      }}
      render={({ field, formState: { errors } }) => (
        <TextField
          {...field}
          variant="outlined"
          label={label}
          fullWidth
          required
          error={errors[name]}
          helperText={errors[name] && "Bitte angeben"}
        />
      )}
    />
  );
}

SimpleTextField.propTypes = {
  control: PropTypes.object,
};

export default SimpleTextField;
