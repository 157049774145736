import React from "react";
import { Grid, Button, Box } from "@mui/material";
import Iconify from "../../../../components/iconify";

export default function FbDataControls({
  formState,
  handleKontrolle,
  handleRiskManagement,
  selectedItem,
  isMobile,
}) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 1,
        left: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "transparent",
        p: isMobile ? 0 : 2,
        pr: isMobile ? 0 : 5,
      }}
    >
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Button
            onClick={handleKontrolle}
            variant="contained"
            startIcon={
              <Iconify icon="fluent-emoji-high-contrast:passport-control" />
            }
            sx={{ mr: 1, background: "#2196F3", textTransform: "none" }}
            disabled={selectedItem?.incidentToRisk}
          >
            {isMobile ? "Kontrolle" : "Neue Kontrolle festlegen"}
          </Button>
          {selectedItem?.incidentType !== "risiko" && (
            <Button
              onClick={handleRiskManagement}
              variant="contained"
              startIcon={<Iconify icon="carbon:tree-fall-risk" />}
              sx={{ mr: 1, background: "#2196F3", textTransform: "none" }}
              disabled={selectedItem?.incidentToRisk}
            >
              {isMobile ? "Risiko" : "Ins Risikomanagement aufnehmen"}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={<Iconify icon="dashicons:update-alt" />}
            disabled={!formState.isDirty}
          >
            {isMobile ? "Speichern" : "Änderung speichern"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
