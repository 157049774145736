import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useController } from "react-hook-form";

//---------------------------------------------------------------------------------
ControlledSelectSurveyType.propTypes = {
  control: PropTypes.object,
};

//---------------------------------------------------------------------------------

const options = [
  { label: "Mitarbeiter", value: "employee" },
  { label: "Patienten", value: "patients" },
  { label: "Zuweiser", value: "referrer" },
];

export default function ControlledSelectSurveyType({ control }) {
  return (
    <Controller
      control={control}
      name="surveyType"
      rules={{ required: "Bitte eine Befragungsart auswählen." }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <InputLabel required id="surveyType-label">
            Befragungsart
          </InputLabel>
          <Select
            {...field}
            labelId="surveyType-label"
            id="surveyType-select-field"
            label="Befragungsart"
          >
            {options.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
