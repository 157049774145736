import { apiSlice } from "../../app/api/apiSlice";

export const surveysApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query({
      query: () => "/survey",
      // keepUnusedDataFor: 5,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: "Survey", id })), "Survey"]
          : ["Survey"],
    }),
    getSurveyById: builder.query({
      query: (id) => `/survey/${id}`,
      providesTags: (result, error, arg) => [
        { type: "Survey", id: result?.id },
      ],
    }),
    addNewSurvey: builder.mutation({
      query: (survey) => ({
        url: "/survey",
        method: "POST",
        body: {
          ...survey,
        },
        invalidatesTags: ["Survey"],
      }),
    }),
    updateSurvey: builder.mutation({
      query: (data) => ({
        url: "/survey",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["Survey"],
      }),
    }),
    deleteSurvey: builder.mutation({
      query: (data) => ({
        url: "/survey",
        method: "DELETE",
        body: data,
        invalidatesTags: ["Survey"],
      }),
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useGetSurveyByIdQuery,
  useAddNewSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
} = surveysApiSlice;
