import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useUpdateSupportMutation } from "../../../features/support/supportApiSlice";

const statusOptions = [
  { label: "Neu", value: "new" },
  { label: "In Bearbeitung", value: "inProgress" },
  { label: "In Prüfung", value: "revision" },
  { label: "Gelöst", value: "solved" },
];

const SupportRequests = ({ supportRequests }) => {
  const [requests, setRequests] = useState(supportRequests);
  const [updateSupport] = useUpdateSupportMutation();

  // Handle status change
  const handleStatusChange = (id, newStatus) => {
    setRequests((prev) =>
      prev.map((req) => (req._id === id ? { ...req, status: newStatus } : req))
    );
  };

  // Handle comment change
  const handleCommentChange = (id, newComment) => {
    setRequests((prev) =>
      prev.map((req) =>
        req._id === id ? { ...req, comment: newComment } : req
      )
    );
  };

  // Save changes to the backend
  const handleSave = async (id) => {
    const updatedRequest = requests.find((req) => req._id === id);
    console.log("updatedRequest :>> ", updatedRequest);
    try {
      const result = await updateSupport(updatedRequest).unwrap();
      if (result) {
        console.log("result :>> ", result);
        alert("Support request updated!");
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    // await updateSupportRequest(id, updatedRequest);
  };

  if (!supportRequests) {
    return (
      <Box
        sx={{
          textAlign: "center",
          marginTop: "2rem",
        }}
      >
        <Typography variant="h6">
          Aktuell existieren noch keine Supportanfragen.
        </Typography>
      </Box>
    );
  }

  return (
    <Paper elevation={3}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Betreff</TableCell>
              <TableCell>Bereich</TableCell>
              <TableCell>Nachricht</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Kommentar</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests &&
              requests.map((sup) => (
                <TableRow key={sup._id}>
                  <TableCell>{sup.area}</TableCell>
                  <TableCell>{sup.subject}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      style={{
                        whiteSpace: "pre-wrap", // Ensures line breaks in multiline text
                        wordWrap: "break-word",
                      }}
                    >
                      {sup.message}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={sup.status}
                        onChange={(e) =>
                          handleStatusChange(sup._id, e.target.value)
                        }
                      >
                        {statusOptions.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={sup.comment}
                      onChange={(e) =>
                        handleCommentChange(sup._id, e.target.value)
                      }
                      multiline
                      fullWidth
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleSave(sup._id)}
                    >
                      Speichern
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SupportRequests;
