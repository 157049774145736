import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Slide, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useInitializeQMFolderMutation } from "../../../../features/files/filesApiSlice";
import ControlledOfficeMenu from "../../../../components/forms/ControlledOfficeMenu";
import { setDialog } from "../../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import FileUpload from "../FileUpload";
import { uploadFolderStructure } from "../../../../app/api/axios/services/files.service";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function InitializeQMFolderStructureDialog({ dialog, handleClose }) {
  const dispatch = useDispatch();
  const [initializeQMFolder, { isLoading }] = useInitializeQMFolderMutation();
  const [selectedOffice, setSelectedOffice] = React.useState("");
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadState, setUploadState] = React.useState({
    show: false,
    file: null,
    loading: false,
  });

  const resetUploadState = () => {
    setUploadState({ show: false, file: null });
  };

  const handleSelectedOffice = (office) => {
    setSelectedOffice(office);
  };

  const handleShowImport = () => {
    setUploadState({ show: true, file: null });
  };

  const handleFile = (file) => {
    setUploadState((prevState) => ({
      ...prevState,
      file,
    }));
  };

  const handleImportFolderStructure = async () => {
    if (!uploadState.file) return;
    setUploadState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    // Upload file
    const formData = new FormData();
    formData.append("file", uploadState.file);
    try {
      let resultUploadFolder = await uploadFolderStructure(
        formData,
        (progress) => {
          setUploadProgress(progress);
        }
      );
      if (resultUploadFolder.status === 200) {
        // File upload was successful
        console.log("resultUploadFolder.data :>> ", resultUploadFolder.data);
        console.log("upload successfull");
        console.log("Start initializing...");
        let result = await initializeQMFolder({
          officeID: selectedOffice,
          initializeFolder: resultUploadFolder.data.path,
        }).unwrap();
        if (result?.status === "SUCCESS") {
          dispatch(
            setDialog({
              title: `QM Ordnerstruktur für ${result?.data?.officeName}`,
              type: "SUCCESS",
              redirect: "/dashboard/files",
              data: {
                successText: `QM Ordnerstruktur für ${result?.data?.officeName} wurde erfolgreich angelegt!`,
              },
            })
          );
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const handleInitializeFolderStructure = async () => {
    console.log("Initialize Folder Structure now...");
    try {
      let result = await initializeQMFolder({
        officeID: selectedOffice,
      }).unwrap();

      console.log("Folder Structure was initialized.");
      console.log("result :>> ", result);

      if (result?.status === "SUCCESS") {
        dispatch(
          setDialog({
            title: `QM Ordnerstruktur für ${result?.data?.officeName}`,
            type: "SUCCESS",
            redirect: "/dashboard/files",
            data: {
              successText: `QM Ordnerstruktur für ${result?.data?.officeName} wurde erfolgreich angelegt!`,
            },
          })
        );
      }
    } catch (error) {
      console.log(
        "There was an error while initializing the folder structure."
      );
      console.log("error :>> ", error);
    }
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose} // Close when click oustside the dialog
      maxWidth="lg"
      fullWidth
      aria-describedby="dialog-initialize"
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dialog?.title}
          <Button onClick={handleClose} color="inherit">
            <Icon width="40" icon="icon-park-solid:close-one" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {!uploadState.show ? (
            <>
              <Grid item xs={12} md={8}>
                <Typography variant="h4">
                  Für welche Praxis soll die Struktur erstellt werden:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <ControlledOfficeMenu
                  selectedOffice={selectedOffice}
                  setSelectedOffice={handleSelectedOffice}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Soll eine vorhandene Ordnerstruktur importiert werden oder
                  eine Standard Ordnerstruktur erstellt werden?
                </Typography>
              </Grid>
              <Grid textAlign={"center"} item xs={12} md={6}>
                <Button
                  sx={{ p: 3, fontSize: "125%" }}
                  disabled={isLoading || uploadState.show}
                  onClick={handleShowImport}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  IMPORTIEREN
                </Button>
                <Typography sx={{ p: 3 }} variant="body1">
                  Eine vorhandene Ordnerstruktur importieren, die anschließend
                  initialisiert wird.
                </Typography>
                <Typography sx={{ p: 3 }} variant="h6">
                  WICHTIG: Die Ordnerstruktur muss als .zip Datei hochgeladen
                  werden.
                </Typography>
              </Grid>
              <Grid textAlign={"center"} item xs={12} md={6}>
                <Button
                  sx={{ p: 3, fontSize: "125%" }}
                  disabled={isLoading}
                  onClick={handleInitializeFolderStructure}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  INITIALISIEREN
                </Button>
                <Typography sx={{ p: 3 }} variant="body1">
                  Aus vorgegebenen Modulen auswählen die als Standardstruktur
                  initialisiert werden.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Button
                  onClick={resetUploadState}
                  color="primary"
                  variant="contained"
                >
                  ZURÜCK
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FileUpload handleFile={handleFile} />
              </Grid>
            </>
          )}
          {uploadState.file && (
            <Grid item xs={12}>
              <Button
                onClick={handleImportFolderStructure}
                disabled={!uploadState.file || uploadState.loading}
                color="primary"
                variant="contained"
                fullWidth
                sx={{ fontSize: "125%" }}
              >
                ORDNERSTRUKTUR HOCHLADEN UND INITIALISIEREN
              </Button>
            </Grid>
          )}
          {uploadProgress > 0 && (
            <Grid item xs={12}>
              <Typography textAlign={"center"} sx={{ p: 3 }} variant="h4">
                Upload: {uploadProgress}%
              </Typography>
              <progress
                style={{ width: "100%" }}
                value={uploadProgress}
                max="100"
              ></progress>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose("REFETCH")}>Beenden</Button>
      </DialogActions>
    </Dialog>
  );
}

InitializeQMFolderStructureDialog.propTypes = {};

export default InitializeQMFolderStructureDialog;
