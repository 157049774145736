import { apiSlice } from "../../app/api/apiSlice";

export const fbmApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFB: builder.query({
      query: () => ({
        url: "/entry",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      // eslint-disable-next-line
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "ErrorOrComplaint", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ErrorOrComplaint", id })),
          ];
        }
        return [{ type: "ErrorOrComplaint", id: "LIST" }];
      },
    }),

    getEntryByID: builder.query({
      query: (entryId) => ({
        url: `/entry/getEntry/${entryId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Task", id: arg }],
    }),
    getHistoryById: builder.query({
      query: (entryId) => ({
        url: `/entry/${entryId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg },
      ],
    }),

    addNewFB: builder.mutation({
      query: (ErrorOrComplaint) => ({
        url: "/entry",
        method: "POST",
        body: {
          ...ErrorOrComplaint,
        },
      }),
      invalidatesTags: [{ type: "ErrorOrComplaint", id: "LIST" }],
    }),
    updateFB: builder.mutation({
      query: (entryUpdate) => ({
        url: "/entry",
        method: "PATCH",
        body: {
          ...entryUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    deleteFB: builder.mutation({
      query: (id) => ({
        url: `/entry`,
        method: "DELETE",
        body: { ...id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    addControl: builder.mutation({
      query: (control) => ({
        url: "/entry/addcontrol",
        method: "POST",
        body: {
          ...control,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    updateControl: builder.mutation({
      query: (control) => ({
        url: "/entry/updateControl",
        method: "PATCH",
        body: {
          ...control,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    updateRiskControl: builder.mutation({
      query: (control) => ({
        url: "/entry/updateRiskControl",
        method: "PATCH",
        body: {
          ...control,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    deleteControl: builder.mutation({
      query: (control) => ({
        url: "/entry/deleteControl",
        method: "DELETE",
        body: {
          ...control,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    deleteMeasure: builder.mutation({
      query: (control) => ({
        url: "/entry/deleteMeasure",
        method: "DELETE",
        body: {
          ...control,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
    getKontrolleFields: builder.query({
      query: (entryId) => ({
        url: `/entry/entry/control/${entryId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event", id: arg }],
    }),
    getMeasureFields: builder.query({
      query: (entryId) => ({
        url: `/entry/entry/measure/${entryId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event", id: arg }],
    }),
    getUserEntryFilter: builder.query({
      query: () => ({
        url: `/entry/entryFilter`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event", id: arg }],
    }),
    entryFilterUpdate: builder.mutation({
      query: (data) => ({
        url: "/entry/entryFilter",
        method: "PATCH",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ErrorOrComplaint", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetFBQuery,
  useGetEntryByIDQuery,
  useGetHistoryByIdQuery,
  useAddNewFBMutation,
  useUpdateFBMutation,
  useDeleteFBMutation,
  useAddControlMutation,
  useUpdateControlMutation,
  useUpdateRiskControlMutation,
  useDeleteControlMutation,
  useDeleteMeasureMutation,
  useGetKontrolleFieldsQuery,
  useGetMeasureFieldsQuery,
  useGetUserEntryFilterQuery,
  useEntryFilterUpdateMutation,
} = fbmApiSlice;
