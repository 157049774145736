import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

function SurveyProtectionCheckbox({ control }) {
  return (
    <div>
      <FormControlLabel
        control={
          <Controller
            name={"protection"}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field} // Automatically handles onChange, checked, etc.
                checked={field.value}
              />
            )}
          />
        }
        label={
          <Typography sx={{ color: "inherit" }}>
            {"Schutz vor wiederholter Eingabe"}
          </Typography>
        }
      />

      <FormHelperText
        sx={{
          textAlign: "left",
          color: "inherit",
        }}
      >
        {
          "Wenn diese Option aktiviert ist, wird ein Wert im Browser gespeichert, so dass eine wiederholte Eingabe erschwert wird. Wird die Umfrage intern an einem Arbeitsplatz mehrfach von verschiedenen Mitarbeiten durchgeführt, sollte diese Option deaktiviert sein."
        }
      </FormHelperText>
    </div>
  );
}

SurveyProtectionCheckbox.propTypes = {
  control: PropTypes.object.isRequired,
};

export default SurveyProtectionCheckbox;
