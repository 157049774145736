import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { getSurveyOptionFromValue } from "../../../utils/surveyUtils";
import { NavLink, useNavigate } from "react-router-dom";

function SurveyItem({ survey, handleDelete, handleEdit, handleQR }) {
  const navigate = useNavigate();

  // Change to button with route

  const handleShowPublicSurvey = (id) => {
    navigate(`/survey/${id}`);
  };

  const handleEvaluate = (id) => {
    navigate(`/dashboard/survey/${id}`);
  };

  return (
    <Box key={survey._id}>
      <Card variant="outlined">
        <CardContent>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            {survey.surveyType === "employee"
              ? "Mitarbeiterbefragung"
              : "Patientenbefragung"}
          </Typography>
          <Typography variant="h5" component="div">
            {survey.surveyTitle}
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
            {survey.surveyDescription}
          </Typography>
          <Typography variant="body2">
            {survey &&
              survey.surveyQuestions.map((q, index) => (
                <>
                  <span key={index}>
                    {index + 1}. {q.question} <br />
                  </span>
                  <span style={{ marginLeft: 15 }}>
                    Art: {q.type && getSurveyOptionFromValue(q.type).label}
                    <br />
                  </span>
                </>
              ))}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography>Antworten: {survey?.surveyAnswers?.length}</Typography>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Stack buttons vertically
              gap: 1, // Space between buttons
              width: "100%", // Make buttons take full width
            }}
          >
            <Button
              component={NavLink}
              to={`/survey/${survey._id}`}
              size="small"
              fullWidth
              color="primary"
              variant="contained"
            >
              ANZEIGEN
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleEdit(survey)}
            >
              BEARBEITEN
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleDelete(survey._id, survey.surveyTitle)}
            >
              LÖSCHEN
            </Button>
            <Button
              size="small"
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => handleQR(survey)}
            >
              QR Code
            </Button>
            <Button
              component={NavLink}
              to={`/dashboard/survey/evaluate/${survey._id}`}
              size="small"
              fullWidth
              variant="contained"
              color="primary"
            >
              Auswertung anzeigen
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}

SurveyItem.propTypes = {
  survey: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default SurveyItem;
