import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import SurveyEvaluationPieChart from "./SurveyEvaluationPieChart";
import { useGetSurveyByIdQuery } from "../../../features/surveys/surveysApiSlice";
import Iconify from "../../../components/iconify";
import { AppWebsiteVisits } from "../app";
import SurveyEvaluationView from "./SurveyEvaluationView";
import Loading from "../../../components/spinners/Loading";

function SurveyEvaluation() {
  const { surveyId } = useParams();
  const { data, isLoading, isError, isFetching } =
    useGetSurveyByIdQuery(surveyId);

  console.log("data :>> ", data);

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Box sx={{ ml: 3 }}>
      <Button
        component={NavLink}
        to={"/dashboard/survey"}
        // onClick={() => handleChangeMenuState("/dashboard/files")}
        variant="contained"
        color="warning"
        sx={{ minWidth: 100, mb: 2 }}
        startIcon={<Iconify icon="mdi:arrow-left" />}
      >
        zurück
      </Button>
      <Typography textAlign={"left"} variant="h2">
        Auswertung
      </Typography>
      {/* <SurveyEvaluationPieChart
        title="Hier den Titel der Frage"
        chartData={[
          { label: "Fehler", value: 20 },
          {
            label: "Beschwerden",
            value: 30,
          },
          { label: "Risiken", value: 50 },
        ]}
        chartColors={[
          "#d9f3f2",
          "#aee3e4",
          "#48aead",
          // theme.palette.warning.main,
          // theme.palette.primary.main,
        ]}
      /> */}

      {data?.survey && (
        <SurveyEvaluationView
          surveyQuestions={data?.survey?.surveyQuestions}
          surveyAnswers={data?.survey?.surveyAnswers}
        />
      )}
    </Box>
  );
}

SurveyEvaluation.propTypes = {};

export default SurveyEvaluation;

{
  /* <AppWebsiteVisits
title="Website Visits"
subheader="(+43%) than last year"
chartLabels={[
  "01/01/2003",
  "02/01/2003",
  "03/01/2003",
  "04/01/2003",
  "05/01/2003",
  "06/01/2003",
  "07/01/2003",
  "08/01/2003",
  "09/01/2003",
  "10/01/2003",
  "11/01/2003",
]}
chartData={[
  {
    name: "Team A",
    type: "column",
    fill: "solid",
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  },
  {
    name: "Team B",
    type: "area",
    fill: "gradient",
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  },
  {
    name: "Team C",
    type: "line",
    fill: "solid",
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  },
]}
/> */
}
