import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from "src/features/notes/notesApiSlice";
import { setDialog } from "../../../../features/dialog/dialogSlice";
import {
  ListItemButton,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  ListItemText,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { AvatarIntern } from "../../../../components/avatar";
import { fToNow } from "../../../../utils/formatTime";
import { useGetTaskByIdQuery } from "../../../../features/task/taskApiSlice";
import { useGetEventByIdQuery } from "../../../../features/event/eventApiSlice";
import dayjs from "dayjs";
import { apiSlice } from "../../../../app/api/apiSlice";

export default function NotificationItem({
  notification,
  currentUserId,
  isUnRead,
  handleClose,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false); // Track loading state

  const [deleteNotification] = useDeleteNoteMutation();
  const [updateNotificationStatus] = useUpdateNoteMutation();

  // Extract link type and ID
  const linkParts = notification.link?.split("/") || [];
  const linkType = linkParts[2];
  const linkId = linkParts.pop();

  // Fetch task or event details based on the link type
  const { data: taskDetails, isLoading: taskLoading } = useGetTaskByIdQuery(
    linkId,
    { skip: linkType !== "task" }
  );
  const { data: eventDetails, isLoading: eventLoading } = useGetEventByIdQuery(
    linkId,
    { skip: linkType !== "calendar" }
  );

  const handleItemClick = async () => {
    try {
      setLoading(true);
      if (isUnRead) {
        handleToggleReadStatus();
      }
      if (linkType === "task") {
        if (!taskLoading && taskDetails) {
          dispatch(
            setDialog({
              content: "EditTaskForm",
              data: taskDetails,
              redirect: `/dashboard/task`,
            })
          );
        } else {
          navigate(`/dashboard/task`);
        }
      } else if (linkType === "calendar") {
        let eventData = null;

        // Standardisierte Datenstruktur für den Dialog
        if (eventDetails) {
          console.log("eventDetails", eventDetails);
          eventData = {
            title: eventDetails?.title || "",
            groupId: "",
            publicId: "",
            url: "",
            recurringDef: eventDetails?.recurrence || null,
            defId: eventDetails?._id || "",
            sourceId: eventDetails?.officeRef || "",
            allDay: eventDetails?.allDay || false,
            hasEnd: !!eventDetails?.end,
            start: eventDetails?.start?.split("T")[0] || "", // Nur Datum
            end: eventDetails?.end?.split("T")[0] || "", // Nur Datum
            extendedProps: {
              _id: eventDetails?._id || "",
              description: eventDetails?.description || "",
              location: eventDetails?.location || "",
              creator: eventDetails?.creator || "",
              color: eventDetails?.color || "#000",
              user: eventDetails?.user,
            },
            ui: {
              backgroundColor: eventDetails?.color || "#000",
              borderColor: eventDetails?.color || "#000",
              display: null,
            },
          };
          console.log("eventData", eventData);
        }

        if (eventData) {
          dispatch(
            setDialog({
              content: "EventDialog",
              data: eventData,
              eventFlag: "EDIT",
              redirect: `/dashboard/calendar`,
            })
          );
        } else {
          navigate(`/dashboard/calendar`);
        }
      } else {
        navigate(notification.link);
      }
    } catch (error) {
      console.error("Error handling notification click:", error);
    } finally {
      setLoading(false);
      handleClose();
      dispatch(apiSlice.util.resetApiState());
    }
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      const result = await deleteNotification({
        id: notification._id,
      });
      dispatch(apiSlice.util.resetApiState());
      handleMenuClose();
    } catch (error) {
      console.error("Failed to delete the notification:", error);
    }
  };

  const handleToggleReadStatus = async () => {
    try {
      await updateNotificationStatus({
        id: notification._id,
        userId: currentUserId,
      }).unwrap();
      dispatch(apiSlice.util.resetApiState());
      handleMenuClose();
    } catch (error) {
      console.error("Failed to update the notification status:", error);
    }
  };

  return (
    <>
      <ListItemButton
        onClick={loading ? null : handleItemClick} // Disable click if loading
        sx={{
          py: 1.5,
          px: 2.5,
          mt: "1px",
          ...(isUnRead && { bgcolor: "action.selected" }),
        }}
      >
        <IconButton sx={{ p: 0, mr: "10px" }}>
          <AvatarIntern
            name={`${notification?.sender?.firstName || ""} ${
              notification?.sender?.lastName || ""
            }`}
          />
        </IconButton>
        <ListItemText
          primary={
            <Typography variant="subtitle2">
              {notification.message}
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.secondary" }}
              >
                &nbsp; {notification.description}
              </Typography>
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Iconify
                icon="eva:clock-outline"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {fToNow(notification.createdAt)}
            </Typography>
          }
        />
        <IconButton onClick={handleMenuClick}>
          <Iconify icon="eva:more-vertical-outline" width={16} height={20} />
        </IconButton>
      </ListItemButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleToggleReadStatus}>
          <ListItemIcon>
            <Iconify
              icon={
                isUnRead
                  ? "eva:checkmark-circle-2-outline"
                  : "eva:slash-outline"
              }
              width={20}
              height={20}
            />
          </ListItemIcon>
          {isUnRead ? "Als gelesen markieren" : "Als ungelesen markieren"}
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
          </ListItemIcon>
          Löschen
        </MenuItem>
      </Menu>
    </>
  );
}
