import React from "react";
import ApexCharts from "react-apexcharts";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Button,
} from "@mui/material";

const SurveyEvaluationView = ({ surveyQuestions, surveyAnswers }) => {
  // Define ranges and labels for different question types
  const SMILEY_MAPPING = {
    1: "😡",
    2: "😠",
    3: "😐",
    4: "🙂",
    5: "😃",
  };

  const OPINION_MAPPING = {
    1: "Stimme überhaupt nicht zu",
    2: "Stimme nicht zu",
    3: "Neutral",
    4: "Stimme zu",
    5: "Stimme voll und ganz zu",
  };

  const aggregateAnswers = (answers, range, mapping) => {
    const counts = range.reduce((acc, key) => {
      const label = mapping ? mapping[key] : key;
      acc[label] = 0; // Initialize all possible values to 0
      return acc;
    }, {});

    // Count occurrences
    answers.forEach((answer) => {
      const label = mapping ? mapping[answer] : answer;
      if (label !== undefined && counts[label] !== undefined) {
        counts[label]++;
      }
    });

    return counts;
  };

  const getAnswerRange = (type) => {
    switch (type) {
      case "points":
        return Array.from({ length: 10 }, (_, i) => i + 1); // Range 1–10
      case "smiley":
        return {
          1: "😡",
          2: "😠",
          3: "😐",
          4: "🙂",
          5: "😃",
        };
      // return ["😡", "😟", "😐", "🙂", "😁"]; // 5 Smileys
      case "opinion":
        return {
          1: "Stimme überhaupt nicht zu",
          2: "Stimme nicht zu",
          3: "Neutral",
          4: "Stimme zu",
          5: "Stimme voll und ganz zu",
        };
      default:
        return [];
    }
  };

  // Aggregate data
  const aggregatedData = surveyQuestions.map((question, index) => {
    const answers = surveyAnswers.map((response) => {
      const answer = response.answers[index];
      return typeof answer === "string" && !isNaN(Number(answer))
        ? Number(answer) // Convert string numbers to integers
        : answer;
    });

    if (question.type === "smiley") {
      return {
        question,
        counts: aggregateAnswers(answers, [1, 2, 3, 4, 5], SMILEY_MAPPING),
      };
    }

    if (question.type === "opinion") {
      return {
        question,
        counts: aggregateAnswers(answers, [1, 2, 3, 4, 5], OPINION_MAPPING),
      };
    }

    if (question.type === "points") {
      return {
        question,
        counts: aggregateAnswers(
          answers,
          Array.from({ length: 10 }, (_, i) => i + 1)
        ),
      };
    }

    if (question.type === "openQuestion") {
      return { question, responses: answers.filter((ans) => ans) };
    }

    return null;
  });

  // Generate ApexCharts options for each quantitative question
  const generateChartOptions = (counts, question, type) => {
    const categories = Array.isArray(getAnswerRange(type))
      ? getAnswerRange(type)
      : Object.values(getAnswerRange(type));

    return {
      series: [
        {
          name: "Anzahl der Antworten",
          data: categories.map((label) => counts[label] || 0),
        },
      ],
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: true, // Enables export options
            },
          },
        },
        xaxis: {
          categories,
          title: {
            text: question,
            offsetY: type === "smiley" ? -20 : 0,
          },
          labels: {
            style: {
              fontSize: type === "smiley" ? "24px" : "14px",
            },
          },
        },
        yaxis: {
          title: {
            text: "Anzahl der Antworten",
          },
          labels: {
            formatter: (value) => Math.round(value),
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value} Antwort${value === 1 ? "" : "en"}`,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => Math.round(value), // Always show integer
        },
      },
    };
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom align="left">
        Anzahl der Antworten: {surveyAnswers && surveyAnswers?.length}
      </Typography>
      {aggregatedData.map((data, index) => {
        if (!data) return null;
        const { question, counts, responses } = data;

        return (
          <Card key={index} sx={{ my: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {question.question}
              </Typography>
              <Divider sx={{ my: 2 }} />

              {/* Bar Chart for Quantitative Data */}
              {question.type !== "openQuestion" && (
                <ApexCharts
                  options={
                    generateChartOptions(
                      counts,
                      question.question,
                      question.type
                    ).options
                  }
                  series={
                    generateChartOptions(
                      counts,
                      question.question,
                      question.type
                    ).series
                  }
                  type="bar"
                  height={350}
                />
              )}

              {/* Open Question Responses */}
              {question.type === "openQuestion" && (
                <Box>
                  <List>
                    {responses.map((response, idx) => (
                      <ListItem
                        key={idx}
                        sx={{ borderBottom: "1px solid #ddd" }}
                      >
                        {response}
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() =>
                      exportResponses(responses, question.question)
                    }
                  >
                    Antworten exportieren
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

// Helper Function: Export Open Responses as a Text File
const exportResponses = (responses, questionTitle) => {
  const blob = new Blob([responses.join("\n")], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${questionTitle}-antworten.txt`;
  link.click();
};

export default SurveyEvaluationView;
