import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import {
  useDeleteSurveyMutation,
  useGetSurveysQuery,
} from "../../../features/surveys/surveysApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import SurveyItem from "./SurveyItem";
import Iconify from "../../../components/iconify";
import Loading from "../../../components/spinners/Loading";

function DisplaySurveys(props) {
  const { data: surveys, isFetching, isLoading } = useGetSurveysQuery();
  const dispatch = useDispatch();
  const [deleteSurvey] = useDeleteSurveyMutation(); // For deleting surveys

  const openDialog = () => {
    dispatch(
      setDialog({
        title: "Befragung erstellen",
        content: "SURVEY",
        type: "CREATE",
        survey: {
          surveyTitle: "",
          surveyDescription: "",
          protection: true,
          surveyQuestions: [],
        },
      })
    );
  };

  const openEditSurveyDialog = (survey) => {
    dispatch(
      setDialog({
        title: "Befragung bearbeiten",
        content: "SURVEY",
        type: "EDIT",
        survey,
      })
    );
  };

  const openShowSurveyQRDialog = (survey) => {
    let parsedURL = new URL(window.location.href);

    dispatch(
      setDialog({
        title: "QR Code für Befragung",
        content: "QR",
        type: "SHOW",
        value: `${parsedURL.origin}/survey/${survey._id}`,
      })
    );
  };

  const handleDelete = async (surveyId, surveyTitle) => {
    try {
      let response = await deleteSurvey({ _id: surveyId }).unwrap(); // Call the delete API
      if (response && response?.status === "success") {
        dispatch(
          setDialog({
            title: "Befragung gelöscht!",
            type: "SUCCESS",
            data: {
              successText: `Befragung ${surveyTitle} wurde erfolgreich gelöscht!`,
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <div>
      <Paper elevation={3} style={{ padding: 20, marginTop: 10 }}>
        <Typography textAlign={"center"} variant="h3" marginBottom={1.5}>
          Mitarbeiter- und Patientenbefragung
        </Typography>

        {/* Information über Fehler und Beschwerden */}
        <div style={{ marginBottom: 10 }}>
          <Typography variant="h6">
            Hier können Sie Fragebögen für Mitarbeiter und Patienten erstellen.
            Diese Fragebögen können dann ausgedruckt werden oder via Link oder
            QR-Code verteilt werden.
          </Typography>
        </div>

        {/* Dialog zum hinzufügen neuer Befragungen */}
        <Button
          onClick={openDialog}
          variant="contained"
          sx={{ minWidth: 300 }}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Neue Befragung
        </Button>
      </Paper>
      <div style={{ marginTop: 15 }}>
        <Grid container spacing={2}>
          {surveys && surveys.length > 0 ? (
            surveys.map((survey) => (
              <Grid item xs={12} md={4} key={survey._id}>
                <SurveyItem
                  survey={survey}
                  handleDelete={handleDelete}
                  handleEdit={openEditSurveyDialog}
                  handleQR={openShowSurveyQRDialog}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary" align="center">
                Es wurden noch keine Befragungen erstellt
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default DisplaySurveys;
